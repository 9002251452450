import React, { useState } from 'react';
import { Modal, Button, FileUpload } from '@/views/styles';
import Icon from '@/components/Icon';
import {uploadRequestWithProgress, formatFileSize} from '@/utils'

const UploadSubmissionModal = ({ type, onClose, onGetFeedback }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onFileChange = async (e) => {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file.size > (type === 'video' ? 5000 : 50 ) * 1024 * 1024) {
        alert(`File size exceeds ${type === 'video' ? '5GB': '50MB'}. Please choose a smaller file.`);
        return;
    }
    setFile(file);
  };

  const upload = async () => {
    if(uploading){return}

    setUploading(true);

    const formData = new FormData();
    formData.append('file', file);
    uploadRequestWithProgress('/upload', formData, (progress) => {
        setProgress(progress);
    }, (data) => {
      onGetFeedback(data.id)
      setUploading(false);
    }, (error) => {
      console.error('EventSource failed:', error);
      setUploading(false);
    });
  }
  return (
    <Modal className="submission upload">
      <div className="bg-click" onClick={onClose}></div>
      <div className="body">
        <h1>
          Upload {type}
          <div onClick={onClose}>
            <Icon name="close" size={40}/>
          </div>
        </h1>

        {file === null && (
          <FileUpload>
            <input
              type="file"
              id="fileUpload"
              accept={type === 'document' ? ".pdf" : "video/*"}
              onChange={onFileChange}
            />
            <Icon name="upload-green" />
            <p><b className="green">Click to upload</b> </p>
            <span>(Max. File size: 5GB)</span>
          </FileUpload>
        )}

        {file !== null && !uploading && (
          <FileUpload onClick={() => onFileChange(null)}>
            <Icon name="file" />

            <p className="green">{file.name}</p>
            <span>File size: {(file.size / 1024 / 1024).toFixed(2)}MB</span>
          </FileUpload>
        )}

        {uploading && (
          <div className="uploading-progress">
            <div>
              <p>{file.name}</p>
              <span>{formatFileSize(file.size)}</span>
            </div>
            <div className="progress">
              <progress value={progress*100} max="100" />
              <p>{(progress * 100).toFixed(0)}%</p>
            </div>
          </div>
        )}

        {!file && (
          <button disabled={true}>
            Submit {type} to get feedback
          </button>
        )}

        {file && (
          <button onClick={() => {upload()} } disabled={false}>
            {!uploading && "Get feedback"}
            {uploading && (
              <>
                <div className="inline-loader"></div>
                {progress === 100 ? "Gathering feedback" : "Uploading..."}
              </>
            )}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default UploadSubmissionModal;
