import React, { useState, useEffect } from 'react';
import { getRequest, gpt, LinkRenderer, ImgRender } from '@/utils';
import Content from '@/components/Content';
import usePrompt from "@/components/Prompt";
import Poppy from '@/public/poppy.png';
import { Sidebar} from './styles';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import useChatLogic from '@/hooks/useChatLogic';
import ChatLayout from '@/components/chat/ChatLayout';

const CourseComponent = () => {
  const { lessonId } = useParams();
  const {
    user,
    chatHistory,
    setChatHistory,
    setLesson,
    inputValue,
    setInputValue,
    isLoading,
    setIsLoading,
    messageLoading,
    setMessageLoading,
    chatContainerRef,
    textareaRef,
    hiddenFileInput,
    handleInputChange,
    handleKeyDown,
    messageRef,
    sendFile,
  } = useChatLogic(lessonId);

  usePrompt(user);

  const [resource, setResource] = useState(null);

  useEffect(() => {
    const getLesson = async () => {
      const data = await getRequest('/resource?id=' + lessonId);
      setResource(data);
      setLesson(data);
      setIsLoading(false);
    }
    getLesson();
  }, [lessonId, setIsLoading, setLesson]);


  if (isLoading) { return <div>Loading...</div>; }

  const sendMessage = async (e, force = false) => {
    if (inputValue.trim() === '' && (typeof e !== 'string')) { return }

    if ((e.key === 'Enter' && !e.shiftKey && inputValue.trim() !== '') || force) {
      e?.preventDefault && e.preventDefault();
      const userMessage = typeof e === 'string' ? e : inputValue.trim();
      const ch = [...chatHistory, { role: 'user', content: userMessage }];
      const combinedContext = `${resource.context}\n\nContext of the resource/article/document:\n${resource.body}`;
      setChatHistory(ch);
      setMessageLoading(true);
      setInputValue('');

      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }

      try {
        gpt(userMessage, combinedContext, "", (response) => {
          setChatHistory([...chatHistory, { role: 'user', content: userMessage }, { role: 'assistant', content: response }]);
        });
      } catch (error) {
        console.error('Error fetching bot response:', error);
      }

      setMessageLoading(false);
    }
  };

  return (
    <>
      <Sidebar className="half" withMainSidebar>
        {resource.course && (
          <Content content={resource.course}></Content>
        )}

        <h1>{resource.name}</h1>

        <Markdown className="markdown sidebar" components={{ a: LinkRenderer, img: ImgRender }}>{resource.body}</Markdown>
      </Sidebar>

      <ChatLayout
        lesson={resource}
        user={user}
        chatHistory={chatHistory}
        messageLoading={messageLoading}
        chatContainerRef={chatContainerRef}
        messageRef={messageRef}
        inputValue={inputValue}
        textareaRef={textareaRef}
        hiddenFileInput={hiddenFileInput}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        sendMessage={sendMessage}
        setMessageLoading={setMessageLoading}
        sendFile={sendFile}
        askModel={sendMessage}
        onBackClick={() => {
          window.location.href = '/library';
        }}
        isHalfScreen
        isContentPage
      >
        {chatHistory.length === 0 &&
          <div className="empty"> <img src={Poppy} alt="Sal" width="90px" height="90px" />
            <small>Poppy</small>
            <h2 style={{ textAlign: 'center', width: '50%' }}>
              Hey! Before you dive in, what's one thing you're curious to learn in this lesson that will help you with building your {(user && user.program === 'alpha-x') ? 'masterpiece' : 'startup'}?
            </h2>
          </div>
        }
      </ChatLayout>
    </>
  );
};

export default CourseComponent;
