import React from 'react';
import PulsingIcon from '@/components/ui/PulsingIcon';
import GradientText from '@/components/ui/GradientText';
import Poppy from '@/public/poppy.png';
import Icon from '@/components/Icon.js';

import styled from 'styled-components';

const PoppyCallingContainer = styled.div`
    display: inline-flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 331px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 16px;
    border: 1px solid var(--White-100, rgba(255, 255, 255, 0.10));
    background: linear-gradient(158deg, rgba(255, 255, 255, 0.04) 0.62%, rgba(54, 5, 91, 0.03) 98.9%);
    box-shadow: 0px 10px 11.2px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(2px);
`;

const StyledImage = styled.img`
    width: 281px !important;
    height: 282px;
    border-radius: 50%;
    margin-top: 10px;

`;

const PoppyHeader = styled.div`
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
`;

const StyledAnswerButton = styled.button`
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    background: rgba(193, 245, 83, 0.10);
    width: 241px;
    border: 5px solid #272529;
`;
const StyledHangupButton = styled.button`
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    background: rgba(246, 49, 53, 0.10);
    width: 241px;
`;
const StyledCallText = styled.div`
   color: var(--White-800, rgba(255, 255, 255, 0.80));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
`;
function PoppyCalling({ initCall, initChat, endCall, vcall }) {
    return (
        <PoppyCallingContainer>
            <PoppyHeader>Poppy</PoppyHeader>
            <GradientText text="Audio call" />
            <StyledImage src={Poppy} alt="Sal" style={{ width: '60px' }} />

            {vcall === null ?

                <StyledAnswerButton onClick={initCall}>
                    <PulsingIcon name="answer" />
                </StyledAnswerButton>

                :
                <StyledHangupButton onClick={endCall}>
                    <Icon name="hangup" />
                </StyledHangupButton>
            }
            <StyledCallText onClick={initChat}>Chat via message instead</StyledCallText>

        </PoppyCallingContainer>

    );
}

export default PoppyCalling;