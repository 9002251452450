import React, { useState, useEffect } from 'react';
import Nav from '@/components/Nav'

import Coaching from '@/views/Coaching'
import Playbook from '@/views/Playbook'
import Onboarding from '@/views/Onboarding'
import Library from '@/views/Library'
import Room from '@/views/Video'
import Breakout from '@/views/Breakout'

import Chat from '@/views/Chat'
import Prework from '@/views/Prework'
import Content from '@/views/Content'

import Admin from '@/views/admin/Admin'
import Teams from '@/views/admin/Teams'
import Team from '@/views/admin/Team'
import Lessons from '@/views/admin/Lessons'
import Calls from '@/views/admin/Calls'
import Resources from '@/views/admin/Resources'

import DocumentViewer from '@/components/DocumentViewer'

import { getRequest } from '@/utils'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import FullStory from 'react-fullstory';
import JSConfetti from 'js-confetti'

import { datadogLogs } from '@datadog/browser-logs';

const jsConfetti = new JSConfetti()
window.confetti = jsConfetti
window.confetti.destroyCanvas()

window.confetti.add = (x) => {
  document.querySelector('body').append(window.confetti.canvas)
  window.confetti.addConfetti(x).then(() => {
    window.confetti.destroyCanvas()
  })
}

datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_PUBLIC_KEY,
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
});


const App = () => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const checkSession = async () => {
      try{
        if(window.location.href.includes('enroll/signup')){
          const urlParams = new URLSearchParams(window.location.search);
          const cohort = urlParams.get('cohort')
          if(cohort){ window.localStorage.setItem('cohort', `${cohort}`); }
          const program = urlParams.get('program')
          if(program){ window.localStorage.setItem('program', `${program}`); }
        }
      }catch(e){
        console.log(e)
      }

      try{
        const {loggedIn} = await getRequest('/connect')

        datadogLogs.logger.info('page loaded');

        if(!loggedIn) {
          window.location.href = `${process.env.REACT_APP_API_URL}/login`
        }

        setReady(true)
      }catch(e){
       window.location.href = `${process.env.REACT_APP_API_URL}/login`
      }
    }
    checkSession()
  }, [])

  return (
    <div className="layout">
      { ready &&
        <Router>
          <FullStory org={"o-1YTTP3-na1"} />
          <Nav />
          <RouteRender />
          <DocumentViewer></DocumentViewer>
        </Router>
      }
    </div>
  );
};


const RouteRender = () => {
  const location = useLocation();

  const MemoizedChat = React.memo(Chat);
  const MemoizedPrework = React.memo(Prework);
  const MemoizedContent = React.memo(Content);

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={300}
        classNames="fade"
      >
        <Routes location={location}>
          <Route path="/" exact={true} element={<Playbook />} />
          <Route path="/video" exact={true} element={<Room />} />
          <Route path="/coaching" exact={true} element={<Coaching />} />

          <Route path="/video/breakout/:breakoutId" exact={true} element={<Breakout />} />
          <Route path="/enroll/:cohort" exact={false} element={<Onboarding />} />
          <Route path="/library" exact={false} element={<Library />} />

          <Route path="/lesson/:lessonId" exact={false} element={<MemoizedChat />} />
          <Route path="/prework/:lessonId" exact={false} element={<MemoizedPrework />} />
          <Route path="/content/:lessonId" exact={false} element={<MemoizedContent />} />


          <Route path="/admin" exact={true} element={<Admin/>} />

          <Route path="/admin/teams" exact={true} element={<Teams/>} />
          <Route path="/admin/team/:teamId" exact={true} element={<Team/>} />
          <Route path="/admin/lessons" exact={true} element={<Lessons/>} />
          <Route path="/admin/calls" exact={true} element={<Calls/>} />

          <Route path="/admin/resources" exact={true} element={<Resources/>} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};


export default App;
