import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDaily, useDailyEvent } from '@daily-co/daily-react';
import { Chat, Tabs } from '@/views/video-styles';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { getRequest, postRequest } from '@/utils';

function ChatBox({ user, callObject, call }) {
  const [bottomTab, setBottomTab] = useState(0);
  const [chats, setChats] = useState([]);
  const [poppyChats, setPoppyChats] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [unreadChats, setUnreadChats] = useState(0);
  const [unreadPoppyChats, setUnreadPoppyChats] = useState(0);
  const daily = useDaily();
  const chatContainerRef = useRef(null);

  const updateChats = useCallback((newChat) => {
    setChats((prev) => [...prev, newChat]);
    if (bottomTab !== 0) setUnreadChats((prev) => prev + 1);
  }, [bottomTab]);

  const updatePoppyChats = useCallback((newChat) => {
    setPoppyChats((prev) => [...prev, newChat]);
    if (bottomTab !== 1) setUnreadPoppyChats((prev) => prev + 1);

  }, [bottomTab]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chats, poppyChats]);

  const handleSendMessage = useCallback(() => {
    if (inputMessage.trim()) {
      daily.sendAppMessage({ chat: { user: user.name, message: inputMessage, tab: bottomTab } }, '*');
      if(bottomTab === 0){
        updateChats({ user: user.name, message: inputMessage });
      }else{
        updatePoppyChats({ user: user.name, message: inputMessage });
        postRequest('/interact/chat', JSON.stringify({
            room: call.id,
            name: call.name,
            text: inputMessage,
            user: user.name,
            timestamp: Date.now()
          }))

      }
      setInputMessage('');
    }
  }, [daily, user.name, inputMessage, updateChats, updatePoppyChats, bottomTab, call]);

  useDailyEvent('app-message',
    useCallback(
      (evt) => {
        if (evt.data.chat) {
          if (evt.data.chat.tab === 0) {
            updateChats(evt.data.chat);
          }else{
            updatePoppyChats(evt.data.chat);
          }
        }
      },
      [updateChats, updatePoppyChats]
    )
  );

  function findUserById(obj, userId) {
    for (const key in obj) {
      if (obj[key].user_id === userId) {
        return obj[key];
      }
    }
    return null;
  }

   useDailyEvent('transcription-message',
    useCallback(
      (evt) => {
        const u = findUserById(callObject.participants(), evt.participantId);
        if(u.user_name === user.name){
          postRequest('/interact', JSON.stringify({
            room: call.id,
            name: call.name,
            text: evt.text,
            user: u.user_name,
            timestamp: evt.timestamp
          }))
        }
      },
      [callObject, call, user]
    )
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const renderMessageContent = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = message.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const handleTabChange = (tabIndex) => {
    setBottomTab(tabIndex);
    if (tabIndex === 0) setUnreadChats(0);
    if (tabIndex === 1) setUnreadPoppyChats(0);
  };

  return (
    <Chat>
      <Tabs>
        <div
          onClick={() => handleTabChange(0)}
          className={`tab${bottomTab === 0 ? ' active' : ''}`}
        >
          Chat
          {unreadChats > 0 && <span className="unread-dot"></span>}
        </div>
        <div
          onClick={() => handleTabChange(1)}
          className={`tab${bottomTab === 1 ? ' active' : ''}`}
        >
          Poppy
          {unreadPoppyChats > 0 && <span className="unread-dot"></span>}
        </div>
      </Tabs>
      {bottomTab === 0 && (
        <div>
          <div className="chats" ref={chatContainerRef}>
            {chats.map((chat, index) => (
              <div key={index}>
                <div className={chat.user === user.name ? 'mine message' : 'message'}>
                  {renderMessageContent(chat.message)}
                </div>
                <div className="details">{chat.user}</div>
              </div>
            ))}
          </div>
          <div className="input">
            <input
              type="text"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button onClick={handleSendMessage}>
              <Icon name="send-arrow" />
            </Button>
          </div>
        </div>
      )}
      {bottomTab === 1 && (
        <div>
          <div className="chats" ref={chatContainerRef}>
            {poppyChats.map((chat, index) => (
              <div key={index}>
                <div className={chat.user === user.name ? 'mine message' : 'message'}>
                  {renderMessageContent(chat.message)}
                </div>
                <div className="details">{chat.user}</div>
              </div>
            ))}
          </div>
          <div className="input">
            <input
              type="text"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button onClick={handleSendMessage}>
              <Icon name="send-arrow" />
            </Button>
          </div>
        </div>
      )}
    </Chat>
  );
}

export default ChatBox;
