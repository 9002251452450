import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DropZone, Button, Modal } from './styles';
import Icon from '@/components/Icon';
import TextSubmissionModal from '@/components/submissions/Text';
import AudioSubmissionModal from '@/components/submissions/Audio';
import UploadSubmissionModal from '@/components/submissions/Upload';
import { getRequest, postRequest, gptFile, gptFeedback, initTracking } from '@/utils';
import ChatLayout from '@/components/chat/ChatLayout';
import useChatLogic from '@/hooks/useChatLogic';

const ChatComponent = () => {
  const { lessonId } = useParams();
  const {
    user,
    setUser,
    chatHistory,
    setChatHistory,
    lesson,
    setLesson,
    inputValue,
    isLoading,
    setIsLoading,
    messageLoading,
    setMessageLoading,
    chatContainerRef,
    textareaRef,
    hiddenFileInput,
    handleInputChange,
    sendMessage,
    handleKeyDown,
    askModel,
    messageRef,
    setActiveStep,
    setExistingSub,
    sendFile,
    submissionMode,
    setSubmissionMode,
    dragOver,
    setDragOver,
    activeStep,
  } = useChatLogic(lessonId);

  const [complete, setComplete] = useState(false);

  const [submissionValue, setSubmissionValue] = useState('');
  const [feedbacked, setFeedbacked] = useState(false);

  useEffect(() => {
    const getLesson = async () => {
      const data = await getRequest('/lesson?id=' + lessonId);
      setLesson(data);

      const ud = await getRequest('/user');
      setUser(ud);
      window.user = ud

      initTracking(ud)

      if (chatHistory.length === 0) {
        setActiveStep(0);
        setChatHistory([{ ...data.steps[0], role: 'assistant' }])
      }

      setIsLoading(false);

      const requestData = { user_id: ud.id, prompt: data.steps[0].content };
      await postRequest('/initialize-chat', JSON.stringify(requestData))
        .then(response => {
          console.log('Chat initialized:', response);
        })
        .catch((error) => {
          console.error('Error initializing chat:', error);
        });

      if (window.location.search.includes('submit=true')) { changeStep('SUBMIT', data) }

      if (data.submission) { await getSubs(ud.team, data.submission.item) }
    }
    getLesson();
  }, []);

  const getSubs = async (team, name) => {
    const sub = await getRequest(`/submission?team=${team}&name=${name}`)
    if (sub && !sub.error) {
      setExistingSub(sub)
    }
  }

  const changeStep = useCallback((step) => {
    if (step === "SUBMIT") {
      const ch = [
        { role: 'assistant', content: lesson?.submission?.prompt },
        { role: 'assistant', content: lesson?.submission?.criteria, finish: true }
      ];
      setChatHistory(ch);
      setSubmissionMode(true);
      return;
    }

    if (step.includes("next:")) {
      setActiveStep((activeStep) => {
        const ch = [{ ...lesson.steps[activeStep + 1], role: 'assistant' }];
        setChatHistory((c) => [...c, ...ch]);
        return activeStep + 1;
      });
      return;
    }

    const ch = [{ role: 'user', content: step }];
    setChatHistory((c) => [...c, ...ch]);
    askModel(step);
  }, [lesson, setChatHistory, askModel, setActiveStep, setSubmissionMode]);

  useEffect(() => {
    const getLesson = async () => {
      const data = await getRequest('/lesson?id=' + lessonId);
      setLesson(data);
      setIsLoading(false);
    };
    getLesson();
  }, [lessonId]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const getMMFeedback = async (type, file) => {
    const message = type.includes('video')
      ? "Gimme some time... I'm watching your video."
      : 'generating feedback...';
    setChatHistory([...chatHistory, { role: 'assistant', content: message }]);

    gptFile(type, file, lesson.context, (data) => {
      setChatHistory([...chatHistory, { role: 'assistant', content: data }]);
    }, user?.program);

    setSubmissionMode(false);
    setFeedbacked(true);
    setSubmissionValue(file);
  };

  const getTextFeedback = async (textValue) => {
    setSubmissionMode(false);
    setSubmissionValue(textValue);
    setChatHistory((c) => [...c, { role: 'user', content: textValue }, { role: 'assistant', content: 'generating feedback...' }]);
    gptFeedback(textValue, lesson.context, (response) => {
      setChatHistory([...chatHistory, { role: 'user', content: textValue }, { role: 'assistant', content: response }]);
    });
    setTimeout(() => { setFeedbacked(true); }, 2000);
  };

  const completeLesson = async () => {
    await getRequest(`/complete-lesson?completed=${lesson.id}`);
    const es = { emojis: lesson.emojis ? lesson.emojis.split(',') : ['🌈', '⚡️', '💥', '✨', '💫', '🌸'] };
    window.confetti.add(es);
    setComplete(true);
  };

  const submit = async () => {
    if (user.team) {
      await postRequest(`/add_submission`, JSON.stringify({
        name: lesson?.submission?.item,
        team: user.team,
        content: submissionValue,
        feedback: chatHistory[chatHistory.length - 1].content,
      }));
    }
    completeLesson();
  };
  const startNextTask = async () => {
    const data = await getRequest('/lessons?program=' + user.program);
    const i = data.findIndex(l => l.id === lesson.id);
    const next = data[i + 1];
    if (next) {
      window.location.href = `/${next.type === 'pre' ? 'prework' : 'lesson'}/${next.id}`;
    } else {
      window.location.href = '/?done=true';
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <ChatLayout
      lesson={lesson}
      user={user}
      chatHistory={chatHistory}
      messageLoading={messageLoading}
      chatContainerRef={chatContainerRef}
      messageRef={messageRef}
      inputValue={inputValue}
      textareaRef={textareaRef}
      hiddenFileInput={hiddenFileInput}
      handleInputChange={handleInputChange}
      handleKeyDown={handleKeyDown}
      sendMessage={sendMessage}
      setMessageLoading={setMessageLoading}
      sendFile={sendFile}
      askModel={askModel}
      isHalfScreen={false}
      onBackClick={() => {
        if (window.location.search.includes('submit=true')) {
          window.location.href = '/library';
        } else {
          window.location.href = '/';
        }
      }}
    >
      <DropZone
        style={{ pointerEvents: dragOver ? 'auto' : 'none', opacity: dragOver ? 1 : 0 }}
        onDrop={sendFile}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <Icon name="discover"></Icon>
        {'Drag and drop a file here'}
      </DropZone>

      {submissionMode && lesson?.submission?.type === 'audio' && (
        <AudioSubmissionModal
          onClose={() => setSubmissionMode(false)}
          onGetFeedback={(f) => { getMMFeedback('audio', f); }}
        />
      )}

      {submissionMode && (lesson?.submission?.type === 'text' || !lesson?.submission?.type) && (
        <TextSubmissionModal
          lesson={lesson}
          user={user}
          onClose={() => setSubmissionMode(false)}
          onGetFeedback={(text) => { getTextFeedback(text); }}
        />
      )}

      {submissionMode && lesson?.submission?.type === 'video' && (
        <UploadSubmissionModal
          onClose={() => setSubmissionMode(false)}
          type="video"
          onGetFeedback={(f) => { getMMFeedback('video', f); }}
        />
      )}

      {submissionMode && lesson?.submission?.type === 'doc' && (
        <UploadSubmissionModal
          onClose={() => setSubmissionMode(false)}
          type="document"
          onGetFeedback={(f) => { getMMFeedback('doc', f); }}
        />
      )}

      {lesson.steps[activeStep].prompts && !messageLoading &&
        <div>
          {lesson.steps[activeStep].prompts.filter((x) => { return x !== '' }).map((prompt, index) => (
            <Button className="cta" key={index + prompt + activeStep} onClick={(e) => {
              e.target.style.display = 'none';
              changeStep(prompt)
            }}>  {prompt.replace("next:", '')} </Button>)
          )}
        </div>
      }

      {(chatHistory[chatHistory?.length - 1]?.finish || (!lesson?.submission && activeStep === lesson.steps.length - 1)) &&
        <Button className="cta teal" onClick={() => { completeLesson() }}>
          Complete Lesson
        </Button>
      }

      {lesson.steps[activeStep] && lesson.steps[activeStep].collect &&
        <div>
          {lesson.steps[activeStep].collect.type === 'submission' &&
            <Button className="cta" onClick={() => { setSubmissionMode(true) }}>  {lesson.submission.cta} </Button>
          }
        </div>
      }
      {!complete && feedbacked && (
        <Button className="cta teal" onClick={submit}>
          Lock it in
        </Button>
      )}

      {complete && (
        <Modal>
          <div className="bg-click"></div>
          <div className="body">
            <Icon name="complete"></Icon>
            <span>WOWIE, THAT'S PRETTY GOOD</span>
            <h1>{lesson?.submission ? lesson?.submission?.item : lesson?.name} complete!</h1>
            <Button onClick={startNextTask}>Start next lesson</Button>
            <Button className="plain" onClick={() => { window.location.href = '/'; }}>Back home</Button>
          </div>
        </Modal>
      )}
    </ChatLayout>
  );
};

export default ChatComponent;
