import React from 'react';
import styled from 'styled-components';

const PentagonWrapper = styled.div`
    position: relative;
    width: 29px;
    height: 27px;
    background: ${props => props.position === 1 ? '#21DFBD' : '#285e61'};
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PentagonNumber = styled.span`
    color: black;
    font-weight: bold;
    font-size: 14px;
    color: ${props => props.position === 1 ? '#000000' : 'color: var(--White-700, rgba(255, 255, 255, 0.70))'};
    font-family: "PP Right Grotesk";
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.14px;
`;

const Pentagon = ({ position }) => (
    <PentagonWrapper position={position}>
        <PentagonNumber position={position}>{position}</PentagonNumber>
    </PentagonWrapper>
);

export default Pentagon;