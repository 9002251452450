import React from 'react';
import styled from 'styled-components';

import Icon from '@/components/Icon';

const StyledBackButton = styled.div`
    align-items: center;
    display: flex;
    grid-gap: 10px;
    cursor: pointer;
`;

function BackButton({ onClick, title }) {
    return (
        <StyledBackButton className="back-button" onClick={onClick}>
            <Icon name="back" size="22"></Icon> {title}
        </StyledBackButton>
    );
}
export default BackButton;

