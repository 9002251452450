import React, { useState, useRef, useEffect } from 'react';

import { useAudioRecorder } from "react-audio-voice-recorder";
import { Modal, Button } from '@/views/styles';
import Icon from '@/components/Icon';
import { transcribeAudio } from '@/utils.js';

const AudioSubmissionModal = ({ onClose, onGetFeedback}) => {
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    isPaused,
    recordingBlob,
    isRecording,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();

  const audioRef = useRef(null);

  const [recordingState, setRecordingState] = useState('start');
  const [playing, setPlaying] = useState(false);


  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (isRecording && mediaRecorder) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(mediaRecorder.stream);
      microphone.connect(analyser);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateVolume = () => {
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
        setVolume(average / 128);
        requestAnimationFrame(updateVolume);
      };

      updateVolume();

      return () => {
        microphone.disconnect();
        audioContext.close();
      };
    }
  }, [isRecording, mediaRecorder]);

  const handleStartRecording = () => {
    setRecordingState('recording');
    startRecording();
  };

  const handleStopRecording = () => {
    setRecordingState('playback');
    stopRecording();
  };

  const secondsToHHMMSS = seconds => new Date(seconds * 1000).toISOString().substr(11, 8);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setPlaying(true)
      } else {
        audioRef.current.pause();
        setPlaying(false)
      }
    }
  };

  const gatherFeedback = async () => {
    audioRef.current.pause();
    setPlaying(false);
    setRecordingState('generating');
    const data = await transcribeAudio(recordingBlob)
    onGetFeedback(data);
  };

  const close = () => {
    setRecordingState('start');
    stopRecording();
    setPlaying(false);
    onClose();
  }

  return (
    <Modal className="submission audio">
      <div className="bg-click" onClick={close}></div>
      <div className="body">
        <h1>
          Record your pitch
          <div onClick={close}>
            <Icon name="close" />
          </div>
        </h1>

        {recordingState === 'generating' && (
          <>
            <div className="recording">
              <div className="ring g r1"></div>
              <div className="ring g r2"></div>
              <div className="ring g r3"></div>

              <div className="green">
                <div className="inline-loader"></div>
              </div>
            </div>
            <button>
              <div className="inline-loader"></div>
              Gathering feedback
            </button>
          </>
        )}

        {recordingState === 'playback' && (
          <>
            <div className="recording" onClick={togglePlayPause}>
              <div className="ring g r1"></div>
              <div className="ring g r2"></div>
              <div className="ring g r3"></div>

              <div className="green">
                <Icon name='play-big'></Icon>
              </div>
            </div>

            <audio src={recordingBlob ? URL.createObjectURL(recordingBlob) : ''} ref={audioRef} controls></audio>

            <button onClick={gatherFeedback}>
              Generate Feedback
            </button>
          </>
        )}

        {recordingState === 'recording' && (
          <>
            <div className="recording nh">
              <div className="ring r r1" style={{transform: `scale(${1 + volume * 0.2})`}}></div>
              <div className="ring r r2" style={{transform: `scale(${1 + volume * 0.3})`}}></div>
              <div className="ring r r3" style={{transform: `scale(${1 + volume * 0.5})`}}></div>

              {mediaRecorder && (
                <div className="red">
                  <Icon name="recording"></Icon>
                </div>
              )}
            </div>
            <span className="time">{secondsToHHMMSS(recordingTime)}</span>
            <button className="stop" onClick={handleStopRecording}>
              Stop Recording
            </button>
          </>

        )}

        {recordingState === 'start' && (
          <>
            <div className="recording" onClick={handleStartRecording}>
              <div className="ring g r1"></div>
              <div className="ring g r2"></div>
              <div className="ring g r3"></div>
              <div className="green">
                <Icon name="mic"></Icon>
              </div>
            </div>
            <button onClick={handleStartRecording}>
              Start Recording
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AudioSubmissionModal;
