import React from 'react';
import styled from 'styled-components';
import BackButton from '@/components/ui/BackButton';

const StyledContainer = styled.div`
  background-color: #1a1a1a;
  color: white;
  padding: 1rem;
  font-family: Arial, sans-serif;
`;

const StyledTitle = styled.h2`
  font-size: 20px;
`;

const StyledRules = styled.div`
  margin-bottom: 1rem;
`;

const StyledStageTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const StyledSectionTitle = styled.h3`
  margin-bottom: 12px;
  margin-top: 23px;

`;

const StyledPointsSection = styled.div`
  margin-top: 1rem;
`;

const StyledRulesContainer = styled.div`
    border-radius: 14px;
    border: 1px solid var(--White-50, rgba(255, 255, 255, 0.05));
    padding: 20px;
    margin-bottom: 18px;
`;

const StyledRulesText = styled.p`
    color: var(--White-800, rgba(255, 255, 255, 0.80));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.28px;
`;

const pointsData = [
    {
        stage: "Stage 1: We’ll award 1st - 3rd for both your rabbitholeathon & hackathon, with each being worth:",
        points: [
            "1st place = 100 points",
            "2nd place = 50 points",
            "3rd place = 25 points"
        ]
    },
    {
        stage: "Stage 2: Getting your one-liner & memo approved (completing stage 2):",
        points: ["100 Points"]
    },
    {
        stage: "Stage 3-5: Points are awarded for your updates at the end of every 2 weeks:",
        points: [
            "1st place = 200 points",
            "2nd place = 100 points",
            "3rd place = 50 points"
        ]
    },
    {
        stage: "Having a networking call with someone external to AlphaX who can help you with your masterpiece:",
        points: ["10 Points"]
    },
    {
        stage: "Supporting others in the AlphaX community by having a productive 1:1 call about your masterpieces:",
        points: ["5 Points"]
    },
    {
        stage: "Presenting in front of your peers during live weekly workshops:",
        points: ["5 Points"]
    },
    {
        stage: "Attending & participating in optional bonus sessions such as peer feedback sessions:",
        points: ["5 Points"]
    },
    {
        stage: "Displaying the AlphaX olympic mindset during a sprint (as determined by AlphaX team):",
        points: ["10 Points"]
    }
];

const PointsEarningGuide = ({ onBackClick }) => {
    return (
        <StyledContainer>
            <BackButton
                title={<StyledTitle>How can I earn points?</StyledTitle>}
                onClick={onBackClick}
            />
            <StyledRules>
                <StyledSectionTitle>Rules:</StyledSectionTitle>
                <StyledRulesContainer>
                    <StyledRulesText>Each month in AlphaX, you can earn points for your accomplishments, participation, and dedication to win up to $400 to help you grow your masterpiece.</StyledRulesText>
                    <br />
                    <StyledRulesText>At the end of every month, the top 5 AlphaX students (based on the points leaderboard) will receive masterpiece grant money, awarding them for their hard work and success over the last month and helping them to further grow their masterpiece. Submit your reason & proof for points!</StyledRulesText>
                </StyledRulesContainer>
            </StyledRules>
            <StyledPointsSection>
                <StyledSectionTitle>You can earn points by:</StyledSectionTitle>
                {pointsData.map((item, index) => (
                    <React.Fragment key={index}>
                        <StyledRulesContainer>
                            <StyledStageTitle>{item.stage}</StyledStageTitle>
                            {item.points.map((point, pointIndex) => (
                                <StyledRulesText>{point}</StyledRulesText>
                            ))}
                        </StyledRulesContainer>

                    </React.Fragment>
                ))}
            </StyledPointsSection>
        </StyledContainer>
    );
};

export default PointsEarningGuide;