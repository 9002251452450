import { useState, useEffect, useRef } from 'react';
import { getRequest, gpt, initTracking, gptFeedback, multiModalChat } from '@/utils';
import useLocalStorageState from 'use-local-storage-state';

const useChatLogic = (lessonId, gptFn=undefined) => {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' });
  const [chatHistory, setChatHistory] = useLocalStorageState('work-chat-history-'+lessonId, { defaultValue: [] })
  const [lesson, setLesson] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState(false);
  const [activeStep, setActiveStep] = useLocalStorageState('work-step-'+lessonId, { defaultValue: 0 });
  const [existingSub, setExistingSub] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [submissionMode, setSubmissionMode] = useState(false);
  const [dragOver, setDragOver] = useState(false);

  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const hiddenFileInput = useRef(null);

  const [collected, setCollected] = useLocalStorageState('prework-collected-' + lessonId, { defaultValue: {} })

  useEffect(() => {
    const getUser = async () => {
      const ud = await getRequest('/user');
      setUser(ud);
      window.user = ud;
      initTracking(ud);
    };
    getUser();
  }, []);

  const handleInputChange = (e) => {
    const textarea = e.target;
    if (textarea && textarea.style) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    }
    setInputValue(e.target.value);
  };

  const sendMessage = async (e, force = false) => {
    if (inputValue.trim() === '' && typeof e !== 'string') return;
    const userMessage = inputValue.trim();
    const ch = [...chatHistory, { role: 'user', content: userMessage }];
    setChatHistory(ch);
    setMessageLoading(true);
    setInputValue('');

    if (textareaRef && textareaRef.current && textareaRef.current.style) {
      textareaRef.current.style.height = 'auto';
    }

    try {
      if(gptFn){
        gptFn()
      }else{
        gpt(userMessage, lesson?.context, "", (response) => {
          setChatHistory([...chatHistory, { role: 'user', content: userMessage }, { role: 'assistant', content: response }]);
        });
      }
    } catch (error) {
      console.error('Error fetching bot response:', error);
    }

    setMessageLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const askModel = async (userMessage) => {
    setMessageLoading(true);
    const ch = [...chatHistory, { role: 'user', content: userMessage}]
    setChatHistory(ch)

    if(lesson.steps[activeStep].id !== 'feedback') {
      gpt(userMessage, lesson.context, (existingSub ? existingSub.content : inputValue), (response) => {
        setChatHistory([...chatHistory, { role: 'user', content: userMessage }, { role: 'assistant', content: response }])
      })
    }else{
      gptFeedback(userMessage, lesson.context, (response) => {
        setChatHistory([...chatHistory, { role: 'user', content: userMessage }, { role: 'assistant', content: response }])
      })
    }
    setMessageLoading(false);
  }

  const sendFile = async (e) => {
    e.preventDefault();
    setDragOver && setDragOver(false);
    setUploading(true);

    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    if (file.size > 1000 * 1024 * 1024) {
      alert('File size exceeds 1GB. Please choose a smaller file.');
      return;
    }

    if (file && !uploading) {
      const message = file.type.includes('video')
        ? "Gimme some time... I'm watching your video."
        : 'reading file...';
      setChatHistory([...chatHistory, { role: 'assistant', content: message }]);

      const data = new FormData();
      data.append('media', file);
      data.append('prompt', lesson.context);

      multiModalChat('chat', data,
        () => {},
        (data) => {
          setSubmissionMode(false);
          setChatHistory([...chatHistory, { role: 'assistant', content: data }]);
        },
        () => {
          setUploading(false);
        },
        (error) => {
          console.error('EventSource failed:', error);
          setUploading(false);
        }
      );
      setInputValue('');
    }
  };


  return {
    user,
    setUser,
    chatHistory,
    setChatHistory,
    lesson,
    setLesson,
    inputValue,
    setInputValue,
    isLoading,
    setIsLoading,
    messageLoading,
    setMessageLoading,
    chatContainerRef,
    textareaRef,
    hiddenFileInput,
    handleInputChange,
    sendMessage,
    handleKeyDown,
    askModel,
    activeStep,
    setActiveStep,
    setExistingSub,
    existingSub,
    collected,
    setCollected,
    sendFile,
    submissionMode,
    setSubmissionMode,
    dragOver,
    setDragOver,
  };
};
export default useChatLogic;
