import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  { getRequest, initTracking } from '@/utils.js';
import  Icon  from '@/components/Icon.js';
import styled from 'styled-components';
import { orderBy } from 'lodash';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 32px;
  h2{
    opacity: 0.65
  }
`;

const Artifacts = styled.div`
  position: relative;
  overflow: visible;
`
const ArtifactsContainer = styled.div`
  position: relative;
  display: flex;
  width: calc(100vw - 125px);
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 70px;
  .empty{
    border: 1px solid var(--hilight);
    border-radius: 8px;
    margin-top: 32px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    algin-items: center;
    justify-content: center;

    h3{
      margin: 16px 0;
      font-size: 16px;
      font-weight: 600;
    }

    p{
      width: 80%;
      font-weight: 200;
      line-height: 1.5;
    }
  }
`;

const Artifact = styled.div`
  margin-right: 10px;
  padding: 32px;
  border-radius: 16px;
  min-width: 225px;
  max-width: 225px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  margin-top: 12px;
  cursor: pointer;
  opacity: 0.8;
  border: 1px solid var(--light);
  background: var(--dark);
  border: 2px solid transparent;
  transition-duration: 0.3s;

  h3{
    font-weight: 600;
    line-height: 1;
    text-align: left;
  }
  p{
    font-size: 12px;
    line-height: 1.2;
    font-weight: 400;
    padding-top: 24px;
    max-height: 175px;
    height: 175px;
    min-height: 175px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

 img{
    background-color: #FFFFFF1A;
    border-radius: 50%;
    margin-top: 40px;
    padding: 16px;
    box-sizing: content-box;
    height: auto;
  }

  &.file{
    justify-content: space-between;
    border: 1px solid #FFFFFF1A;
    background: #131313;
    img{
      opacity: 0.2;
    }
  }

  &:hover{
    opacity: 1;
    border-color: var(--grey);
  }

  > div{
    font-size: 16px;
    line-height: 22px;
  }
  button{
    margin-top: 20px;
  }
`;


const ArrowButton = styled.div`
  position: absolute;
  cursor: pointer;
  background-color: var(--teal);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 5;
  top: 90%;
  opacity: 0.5;
  transition-duration: 0.3s;

  &:hover{
    opacity: 1;
  }

  &.right{
    left: calc(100vw - 225px);
  }
  &.left{
    left: -15px;
  }
`
const ResroucesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - 125px);
  margin: 32px 0;
  margin-bottom: 48px;
`;

const Resource = styled.div`
  width: 205px;
  background: var(--dark);
  position: relative;
  border-radius: 16px;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 32px;
  opacity: 0.7;
  transition-duration: 0.3s;
  border: 2px solid transparent;

  &:hover{
    opacity: 1;
    transform: scale(1.05);
    border-color: var(--grey);
    z-index: 9;
    img{
      transform: translateY(-50px) translateX(25px) scale(1.2) rotate(5deg);
    }
  }

  div{
    padding: 32px;
  }

  img{
    min-width: 100%;
    height: 180px;
    padding: 10px;
    object-fit: cover;
    position: relative;
    transition-duration: 0.3s;
  }

  span{
    font-size: 12px;
    padding-bottom: 8px;
    opacity: 0.5;
    text-transform: uppercase;
    display: block;
  }
`;


const SUBTITLE_GROUPS = {
  'alpha-x':['Test Your Ideas', 'Define Your Project', 'Secure Your First Wins', 'Truly Stand Out', 'Become The Best In The World'],
  'betacamp': ['Ideation, Market Research, and MVP Development', 'Sales and Marketing', 'Growth and Scaling', 'Pitching and Demo Day']
}

const Build = () => {
  const [libContent, setLibContent] = useState([])
  const [subs, setSubs] = useState([])
  const [user, setUser] = useState([])
  const [subTitleGroups , setSubTitleGroups] = useState(SUBTITLE_GROUPS['betacamp'])

  useEffect(() => {
    const getLib = async () => {
      const u = await getRequest('/user')

      setUser(u)
      initTracking(u)

      let libData = null
      if(u && u.program){
        setSubTitleGroups(SUBTITLE_GROUPS[u.program])
        libData = await getRequest('/library?program=' + u.program)
      }else{
        libData = await getRequest('/library')
      }

      const categorizedData = {};

      libData.forEach((item) => {
        if (!categorizedData[item.type]) {
          categorizedData[item.type] = [];
        }
        categorizedData[item.type].push(item);
      });

      const result = Object.keys(categorizedData).map(type => ({
        type,
        resources: categorizedData[type]
      }));

      setLibContent(result)
      return
    }

    const getSubs = async () => {
      const subData = await getRequest('/submissions')
      const lessons = await getRequest('/lessons')

      if(subData) {
        const ls = subData.map((s) => {
          const lesson = lessons.find((l) => {
            if(!l.submission){ return false }
            return l.submission.item === s.name
          })

          return { ...s, url: lesson ? lesson.id : ''}
        })

        setSubs(ls)
      }
    }

    getSubs()
    getLib()
  }, [])


  const containerRef = useRef(null);

  const [isBackButtonHidden, setIsBackButtonHidden] = useState(true);
  const [isForwardButtonHidden, setIsForwardButtonHidden] = useState(false);

  const isFile = (file) => {
    return file.endsWith('.pdf') ||
    file.endsWith('.mp4') ||
    file.endsWith('.mov') ||
    file.endsWith('.avi') ||
    file.endsWith('.ogg') ||
    file.endsWith('.mkv') ||
    file.endsWith('.png') ||
    file.endsWith('.jpg') ||
    file.endsWith('.doc') ||
    file.endsWith('.docx') ||
    file.endsWith('.ppt') ||
    file.endsWith('.pptx')
  }


  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      setIsBackButtonHidden(container.scrollLeft === 0);
      setIsForwardButtonHidden(container.scrollLeft + container.clientWidth >= container.scrollWidth);
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

   const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -550,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 550,
        behavior: 'smooth',
      });
    }
  };

  const navigate = useNavigate();

  const libData = libContent.filter((lib) => lib.type !== 'Resources & Guides');
  const resourceData = libContent.filter((lib) => lib.type === 'Resources & Guides');
  return (
    <Container>
      <h1> {user.program === 'alpha-x' ? 'Masterpiece' : 'Startup'} Details</h1>
      <h2>Key Info from Your Submissions</h2>

      <Artifacts>

        { subs.length > 0 &&
          <>
            {!isBackButtonHidden && <ArrowButton className="left" onClick={scrollLeft}><Icon name="back"/></ArrowButton> }
            {!isForwardButtonHidden && <ArrowButton className="right" onClick={scrollRight}><Icon name="next"/></ArrowButton> }
          </>
        }

        <ArtifactsContainer ref={containerRef}>
          {subs.length > 0 && subs.filter(s => s.url).map((sub, i) => (
                <Artifact key={i + 'sub'} onClick={() => { navigate(`/lesson/${sub.url}?submit=true`) }}>
                  <h3>{sub.name}</h3>
                  <Icon name="edit"/>
                  { !isFile(sub.content) ? <p>{sub.content}</p> : <p></p> }
            </Artifact>
          ))}

          {subs.length === 0 &&
            <div className="empty">
                  <Icon name="discover"/>
              <h3>No Submissions yet</h3>
              <p> Complete steps in your playbook to see your {user.program === 'alpha-x' ? '' : "team's"} submissions here </p>
            </div>
          }
        </ArtifactsContainer>
      </Artifacts>

      {libData.length > 0 && libData.map((c, j) => ( <div  key={j + '-res-container'}>
        <h1>{c.type}</h1>
        <h2>{subTitleGroups[j]}</h2>
        <ResroucesContainer>
          { c.resources.length > 0 && orderBy(c.resources, ['order'], ['asc']).map((sub, i) => {
            return (
            <Resource onClick={() => { navigate(`/content/${sub.id}`) }} key={i + '-resrouce'}>
              <img alt="cover art" src={sub.img}/>
              <div>
                <span>{sub.detail}</span>
                <p>{sub.name}</p>
              </div>
            </Resource>
          )})}
        </ResroucesContainer>
      </div> ))}
      {/* Add resource and guide section */}
      {resourceData && resourceData.length > 0 && resourceData.map((c, j) => ( <div  key={j + '-res-container-resource'}>
        <h1>{c.type}</h1>
        <ResroucesContainer>
          { c.resources.length > 0 && orderBy(c.resources, ['order'], ['asc']).map((sub, i) => {
            return (
            <Resource onClick={() => { navigate(`/content/${sub.id}`) }} key={i + '-resrouce'}>
              <img alt="cover art" src={sub.img}/>
              <div>
                <span>{sub.detail}</span>
                <p>{sub.name}</p>
              </div>
            </Resource>
          )})}
        </ResroucesContainer>
      </div> ))}
    </Container>
  );
};

export default Build;
