import React, { useState, useEffect } from 'react';
import Icon from '@/components/Icon';
import styled from 'styled-components';
import { postRequest } from '@/utils.js';
import { ToastContainer, toast } from 'react-toastify';

const StyledSidebarForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--admin);
  border-radius: 8px;
  max-width: 387px;
  min-width: 387px;
  overflow-y: scroll;
  height: 100vh;

  .step-indicator {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #0F172A;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    background: #E2E8F0;
    border-radius: 100px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    row-gap: 16px;
    margin-top: 16px;
    
    .complete-label, .pending-label {
      color: white;
      padding: 8px 16px;
      border-radius: 8px;
      cursor: pointer;
      border: none;
    }
    .complete-label {
      background-color: green;
    }
    .pending-label {
      background-color: red;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    row-gap: 32px;
    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      
      label {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 600;
      }
      
      input, textarea {
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        padding: 16px;
        border: 1px solid #CBD5E1;
      }
      
      textarea {
        min-height: 150px;
      }
    }
  }
`;
const StyledButton = styled.button`
  padding: 8px 16px;
  height: 40px;
  color: #F5F5F5;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid var(--slate-200, #E2E8F0);
  background: #334155;
`
const StyledFlex = styled.div`
    display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
    align-items: center;
`;
const StyledCloseIcon = styled.div`
   cursor: pointer;
   padding: 8px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 6px; 
   border: 1px solid #E2E8F0;
   background: #FFF;
   display: flex;
`
const StyledHeading = styled.h3`
    color: #000;
    font-size: 24px;
    font-weight: 600;
`
const StyledImg = styled.img`
    border-radius: 16px;
    width: 100%;
`;
const PointsForm = ({ onClose, data, teamName, onSubmit, userId, teamId }) => {
    const [formData, setFormData] = useState({
        img: data?.img_proof || '',
        reason: data?.reason || '',
        points_awarded: data?.points_awarded || '',
        id: data?.id,
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({ points_awarded: '', reason: '' });
    const [touched, setTouched] = useState({ points_awarded: false, reason: false });

    useEffect(() => {
        const newErrors = { points_awarded: '', reason: '' };
        if (formData.points_awarded.length === 0) {
            newErrors.points_awarded = 'Points Awarded cannot be empty';
        }

        if (formData.reason.length === 0 && (!formData.img || formData.img === '')) {
            newErrors.reason = 'Reason cannot be empty';
        }

        setErrors(newErrors);
        setIsFormValid(!newErrors.points_awarded && !newErrors.reason);
    }, [formData]);

    useEffect(() => {
        setFormData({
            img: data?.img_proof || '',
            reason: data?.reason || '',
            points_awarded: data?.points_awarded || '',
            id: data?.id,
        })
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched({
            ...touched,
            [name]: true,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid) {
            addPoints(formData)
        }
    };

    const addPoints = async (e) => {
        onSubmit();
        const endpoint = data.id ? '/admin/point_submission/update' : '/admin/point_submission/add';
        const payload = data.id ? {
            points: e.points_awarded,
            team_id: parseInt(teamId, 10),
            id: e.id,
        } : {
            reason: e.reason,
            points: e.points_awarded,
            team_id: parseInt(teamId, 10),
            submitted_by: userId,
        };

        try {
            const response = await postRequest(endpoint, JSON.stringify(payload));
            if (response?.error) {
                throw new Error("An unexpected error occurred. Please try again later!");
            }
            onSubmit();
        } catch (error) {
            toast.error(error.message, {
                theme: "dark",
                hideProgressBar: true,
            });
        }
    };

    return (
        <>
            <StyledSidebarForm>
                <StyledFlex justifyContent={data?.id ? "space-between" : "flex-end"}>
                    {data?.id && <div className="step-indicator">{data?.id}</div>}
                    <StyledCloseIcon onClick={() => { onClose(false) }}>
                        <Icon name="close-black" size={16} />
                    </StyledCloseIcon>
                </StyledFlex>

                <>
                    <div className="header">
                        <StyledHeading>{data?.img_proof ? "Student submission" : `Award points to ${teamName}`}</StyledHeading>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            {data.img_proof && data.img_proof !== '' ?
                                <StyledImg src={data.img_proof} />

                                :
                                <>
                                    <label htmlFor="reason">Reason</label>
                                    <input
                                        name="reason"
                                        id="reason"
                                        value={formData.reason}
                                        onChange={handleChange}
                                        placeholder="Ex. Completed Hackathon (max 150 character)"
                                        onBlur={handleBlur}
                                    />
                                    {touched.reason && errors.reason && <ErrorInput errorMessage={errors.reason} />}
                                </>
                            }

                        </div>
                        <div className="row">
                            <label htmlFor="sub">Points awarded</label>
                            <input
                                type="text"
                                name="points_awarded"
                                id="content"
                                value={formData.points_awarded}
                                onChange={handleChange}
                                placeholder="Ex. 5"
                                onBlur={handleBlur}
                            />
                            {touched.points_awarded && errors.points_awarded && <ErrorInput errorMessage={errors.points_awarded} />}
                        </div>
                        <StyledButton style={
                            {
                                cursor: !isFormValid ? 'not-allowed' : 'pointer',
                            }
                        }
                            type="submit"
                            disabled={!isFormValid}
                        >
                            Award points
                        </StyledButton>
                    </form>
                </>
            </StyledSidebarForm>
            <ToastContainer />
        </>
    );
};
const StyledErrorInput = styled.p`
  color: red !important;
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
`;
const ErrorInput = ({ errorMessage }) => {
    return <StyledErrorInput>{errorMessage}</StyledErrorInput>

}
export default PointsForm;
