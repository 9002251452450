import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest, PROGRAMS } from '@/utils.js';
import { AdminView, AdminHeader, AdminSidebarForm, AdminTable, Button } from '@/views/styles.js';
import Nav from '@/components/admin/Nav.js';
import ProgressBar from '@/components/admin/ProgressBar.js';

import Icon from '@/components/Icon';
import UserSelect from '@/components/admin/UserSelect';

import styled from 'styled-components';

const Team = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--admin);
  img{
    border-radius: 50%;
  }
  &:last-child{
    border-bottom: none
  }
  .cohort{
    min-width: 100px;
    max-width: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    p{
      margin: 2px;
      font-size: 11px;
      padding: 4px;
      border: 1px solid black;
      border-radius: 4px;
    }
  }

  .advisor{
    min-width: 100px;
    max-width: 100px;
    display: flex;
    aling-items: center;
    justify-content: center;
    img{
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }

  .status{
    min-width: 100px;
    max-width: 100px;
    font-size: 11px;
  }
  .members{
    display: flex;
    flex-direction: row;
    min-width: 100px;
    max-width: 300px;
    img{
        width: 16px;
        height: 16px;
        object-fit: cover;
        margin-right: 8px
    }
  }
  .team{
    display: flex;
    align-items: center;
    min-width: 250px;
    max-width: 250px;
    img{
      width: 40px;
      height: 40px;
    }
    img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }
`

const Filters = styled.div`
display: flex;
align-items: center;
padding: 8px 32px;

input, select{
  margin-right: 16px;
  padding: 8px;
  color: black;
  border: 1px solid var(--admin);

  border-radius: 8px;
  &:focus{
    outline: none
  }
}
`

const Teams = () => {
  const navigate = useNavigate();

  const [adding, setAdding] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showAdvisingModal, setShowAdvisingModal] = useState(false)
  const [teams, setTeams] = useState([])
  const [user, setUser] = useState({permissions: []})
  const [cohorts, setCohorts] = useState([])
  const [users, setUsers] = useState({ advisor: [], user: [] })

  const [newAdvisors, setNewAdvisors] = useState([])

  const [userFilter, setUserFilter] = useState('')

  const loadTeams = async (permissions) => {
    setLoading(true)
    const is = permissions.includes('sa')
    const data = await getRequest(`/admin/${is ? 'sa' : 'all'}/teams`)
    setTeams(data)
    setLoading(false)
  }

  const loadData = async () => {
    const u = await getRequest('/user')
    const us = await getRequest('/admin/users')
    const cos = await getRequest('/admin/cohorts')
    setUser(u)
    setCohorts(cos)
    setUsers(us)
    setNewAdvisors(us.advisor)
    loadTeams(u.permissions)
  }

  useEffect(() => { loadData() }, [])

  const changeRoute = (team) => {
    navigate('/admin/team/' + team.id)
  }

  const teamStatus = (team) => {
    const due_submissions = team.all_submissions.filter((sub) => new Date(sub.due[team.cohort.id]) <= new Date()).length

    const overdue_weight = ((1 + team.submissions.length) / (1 + due_submissions)) * 0.8
    const revenue_weight = (team.revenue / 500) * 0.2
    const ots = (revenue_weight + overdue_weight)
    if(ots >= 0.5 || ots === 0){
      return ({ el: (<div className="status">On Track</div>), status: true })
    }
    return ({ el: (<div className="status not">Needs Help</div>), status: false })
  }

  const createTeam = async (e) => {
    await postRequest('/admin/team/add', JSON.stringify(e));
    loadTeams(user.permissions);
    setAdding(null);
  };

  const updateAdvisors = async (e) => {
    await postRequest('/admin/advisors', JSON.stringify(e));

    loadData()
    setUserFilter('');
    setShowAdvisingModal(false);
  };


  const filteredUsers = (type) => {
    const added = type === 'user' ? adding.members : adding.advisors;
    return users[type].filter(
      (user) => !added.find((addedUser) => addedUser.id === user.id)
    );
  };


  const removeTeamMember = (index) => {
    setAdding({
      ...adding,
      members: adding.members.filter((_, i) => i !== index),
    });
  };

  const removeAdvisor = (index) => {
    setAdding({
      ...adding,
      advisors: adding.advisors.filter((_, i) => i !== index),
    });
  };


  const [filters, setFilters] = useState({
    status: '',
    progress: '',
    startupAdvisors: '',
    cohort: ''
  });

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const filteredTeams = useCallback((t) => {
    return t.filter(team => {
      return (
        (filters.teamName ? team.name.toLowerCase().includes(filters.teamName.toLowerCase()) : true) &&
        (filters.status ? ( teamStatus(team).status === (filters.status === 'OnTrack') ) : true) &&
        (filters.startupAdvisors ? team.advisor == filters.startupAdvisors : true) &&
        (filters.cohort ? team.cohort.id == filters.cohort : true)
      );
    });
  }, [filters]);

  return (
    <AdminView>
      <Nav />

      <main>
        <AdminHeader>
          <h1>Teams</h1>
          <div>
            {!adding &&
              <Button
                className="admin secondary"
                onClick={() => { setShowAdvisingModal(true); setUserFilter('') }} >
                Add An Advisor
              </Button>
            }

            <Button
              className="admin primary"
              onClick={() => {setAdding({members: [], advisors: [], program: PROGRAMS[0]}); setShowAdvisingModal(false) }} >
              <Icon name="add" size={10}></Icon>
              Add Team
            </Button>

          </div>
        </AdminHeader>

        <Filters>
          <input
            type="text"
            placeholder="🔍 Filter teams"
            onChange={(e) => handleFilterChange('teamName', e.target.value)}
          />
          { user.permissions.includes('sa') &&
            <select onChange={(e) => handleFilterChange('status', e.target.value)}>
              <option value="">📊 Status</option>
              <option value="OnTrack">On Track</option>
              <option value="NeedsHelp">Needs Help</option>
            </select>
          }
           <select onChange={(e) => handleFilterChange('startupAdvisors', e.target.value)}>
            <option value="">🗣 Startup Advisors</option>
            {users?.advisor?.map(user => (
              <option key={user.id} value={user.id}>{user.name}</option>
            ))}
          </select>
          <select onChange={(e) => handleFilterChange('cohort', e.target.value)}>
            <option value="">🎓 Cohort</option>
            {cohorts.map(cohort => (
              <option key={cohort.id} value={cohort.id}>{cohort.program} {cohort.session} {cohort.class_of}</option>
            ))}
          </select>
        </Filters>

        { loading && <div className="loader max-center"> </div> }

        { (!loading && teams.length === 0) && <div className="max-center"> No Teams Available.</div> }

        <AdminTable>
          <div className="head">
            <p className="team"> Team</p>
            { user.permissions.includes('sa') && <p className="status">Status</p> }
            { user.permissions.includes('sa') && <p className="progress"> Submissions</p> }
            <p className="progress"> Revenue</p>
            <p className="advisor">Advisor</p>
            <p className="members">Members</p>
            <p className="cohort">Cohort</p>
          </div>

          {filteredTeams(teams).map((team, i) => {
            return (
              <Team key={i + 'team' + team.id} onClick={() => { changeRoute(team) }}>
                <div className="team">
                  <img src={team.logo_url} alt="team logo" />
                  <span>{team.name}</span>
                </div>

                { user.permissions.includes('sa') &&
                  <div className="status">
                    {teamStatus(team).el}
                  </div>
                }
                { user.permissions.includes('sa') &&
                  <div>
                    <ProgressBar progress={Array.from(new Set(team.submissions.map((x) => { return x.name }))).length} max={team.all_submissions.length} status={teamStatus(team).status}/>
                  </div>
                }
                <div>
                  <ProgressBar progress={team.revenue} max={500} label={'$'} status={teamStatus(team).status}/>
                </div>

                <div className="advisor">
                  {
                    users.advisor.length > 0 &&
                    <img src={team.advisor !== null ? users.advisor.find((a) => a.id == team.advisor)?.picture : 'https://via.placeholder.com/150.png'} alt="profile"/>
                  }
                </div>

                <div className="members">
                  {team.members && team.members.map((member, i) => {
                    return (
                      <div key={i + member.name + 'member' + team.id}>
                        <img src={member?.picture} alt="profile"/>
                      </div>
                    )
                  })}
                </div>
                <div className="cohort">
                  <p>{team.cohort.program}</p>
                  <p>cohort {team.cohort.session}</p>
                  <p>{team.cohort.class_of}</p>
                </div>
              </Team>
            )
          })}

        </AdminTable>
      </main>

      {adding !== null &&
        <AdminSidebarForm>
          <AdminHeader className="sec-header">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div onClick={() => { setAdding(null)} }>
                <Icon name="close" size={8} ></Icon>
              </div>
              <h2>Add Team</h2>
            </div>
            <div>
              {!adding._id &&
                <Button className="admin primary" onClick={() => { createTeam(adding) }}> Create Team </Button>
              }
            </div>

          </AdminHeader>

          <form>

            <div className="row">
              <img src={adding.logo_url || 'https://via.placeholder.com/150' } alt="img" />
              <div className="group img-group">
                <label htmlFor="img">Team Logo</label>
                <input type="text" name="img" id="img" placeholder="upload image" value={adding.logo_url} onChange={(e) => {setAdding({...adding, logo_url: e.target.value}) }} />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="name">Team Name</label>
                <input
                  type="text" name="name" id="name" placeholder="Team Salami"
                  value={adding.name}
                  onChange={(e) => {setAdding({...adding, name: e.target.value}) }}/>
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="program">Program</label>
                <select value={adding.program} onChange={(e) => { setAdding({...adding, program: e.target.value}) }}>
                  {PROGRAMS.map(program => <option value={program} key={program}>{program}</option>)}
                </select>
              </div>

              <div className="group">
                <label htmlFor="program">Cohort #</label>

                { (cohorts.map(group => group.session).includes(adding.cohort) || !adding.cohort) &&
                  <select value={adding.cohort} onChange={(e) => { setAdding({...adding, cohort: parseInt(e.target.value) }) }}>
                    {!adding._id && <option value="" selected default disabled>select cohort</option> }
                    {cohorts.filter(group => group.program === (adding.program || PROGRAMS[0])).map(group => <option value={group.session} key={group.session}>{group.session}</option>)}
                    <option value="-1">+ Add New Group</option>
                  </select>
                }

                { (!cohorts.map(group => group.session).includes(adding.cohort) && adding.cohort) &&
                  <input type="number" name="group" id="group" placeholder="group"
                    value={`${adding.cohort}`}
                    onChange={(e) => {setAdding({...adding, cohort: parseInt(e.target.value)}) }}
                  />
                }
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="site">Website</label>
                <input
                  type="text" name="site" id="site" placeholder="www.salami.com"
                  value={adding.website}
                  onChange={(e) => {setAdding({...adding, website: e.target.value}) }}/>
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="one">One Line statement</label>
                <textarea
                  type="text" name="one" id="one" placeholder="One line statement"
                  value={adding.description}
                  onChange={(e) => {setAdding({...adding, description: e.target.value}) }}/>
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label>Team</label>

                {adding.members.map((member, index) => (
                  <div key={index} className="user">
                    <img src={member?.picture}/>
                    <p>{member.name}</p>
                    <button type="button" onClick={() => removeTeamMember(index)}>x</button>
                  </div>
                ))}

                <UserSelect
                  options={filteredUsers('user')}
                  onSelect={(member) => { setAdding({ ...adding, members: [...adding.members, member], }) }}
                  placeholder="Add student"
                />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label>Startup Advisors</label>

                {adding.advisors.map((advisor, index) => (
                  <div key={index} className="user">
                    <img src={advisor?.picture}/>
                    <p>{advisor.name}</p>
                    <button type="button" onClick={() => removeAdvisor(index)}>x</button>
                  </div>

                ))}

                {adding.advisors.length === 0 &&
                  <UserSelect
                    options={filteredUsers('advisor')}
                    onSelect={(advisor) => { setAdding({ ...adding, advisors: [...adding.advisors, advisor], }) }}
                    placeholder="Add startup advisor"
                  />
                }
              </div>
            </div>
          </form>
        </AdminSidebarForm>
      }

      { showAdvisingModal &&
        <AdminSidebarForm>
          <AdminHeader className="sec-header">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div onClick={() => { setShowAdvisingModal(false)} }>
                <Icon name="close" size={8} ></Icon>
              </div>
              <h2>Add Startup Advisors</h2>
            </div>
            <div>
                <Button className="admin primary" onClick={() => { updateAdvisors(newAdvisors) }}> Update </Button>
            </div>
          </AdminHeader>

          <form>
            <div className="row">
              <div className="group">
                <input type="text" placeholder="🔭 Filter users" value={userFilter} onChange={(e) => { setUserFilter(e.target.value) }} />
              </div>
            </div>

            {[...users.advisor, ...users.user].filter((x) => { return x.name.toLowerCase().includes(userFilter.toLowerCase())  }).map((u, i) => (
              <div className="row" key={i}>
                  <img src={u?.picture} style={{width: '40px', height: '40px', borderRadius: '50%'}}/>
                  <p>{u.name}</p>
                  <input type="checkbox" value={u.id} onChange={(e) => {
                    if(e.target.checked){
                      setNewAdvisors([...newAdvisors, u])
                    } else {
                      setNewAdvisors(newAdvisors.filter((a) => { return a.id !== u.id }))
                    }
                  }} checked={newAdvisors.find((a) => { return a.id === u.id })} />
              </div>
            ))}
          </form>
        </AdminSidebarForm>
      }
    </AdminView>
  );
};

export default Teams;
