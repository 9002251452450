import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getRequest, postRequest, gptCoach, initTracking } from '@/utils.js';
import Loader2 from '@/components/CoverLoader.js';
import {
  Onboarding,
  Container,
  Header,
  Todos,
  Tasks,
  Task,
  Goal,
  TaskGoalForm
} from './coaching-styles';
import PoppyImg from '@/public/poppy.png';
import Poppy from '@/public/poppy.png';
import Anon from '@/public/anon.png';
import Icon from '@/components/Icon.js';
import Markdown from 'react-markdown';
import { Chat, Loader, Message, Avatar, ChatInput, ChatInputContainer, Button } from './styles';
import useChatLogic from '@/hooks/useChatLogic';
import Vapi from "@vapi-ai/web";
import endcall from '@/public/endcall.mp3';
import startcall from '@/public/startcall.mp3';
import PoppyCalling from '@/components/calls/PoppyCalling';
const endCallSound = new Audio(endcall);
const startCallSound = new Audio(startcall);

function extractKeyNumber(str) {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
}

const Coaching = () => {
  const [tabMode, setTabMode] = useState(null)
  const [vcall, setVcall] = useState(null)
  const [currentTask, setCurrentTask] = useState(null)
  const [tasks, setTasks] = useState([])
  const [goals, setGoals] = useState([])
  const [team, setTeam] = useState(null)
  const [loading, setLoading] = useState(true)
  const [goalInputValue, setGoalInputValue] = useState('')
  const [masterpeiceInputValue, setmasterPeiceInputValue] = useState('')
  const [callHistory, setCallHistory] = useState([])
  const [taskGoalFormOpen, setTaskGoalFormOpen] = useState(false);
  const [taskFormInput, setTaskFormInput] = useState('')
  const [onboardingIndex, setOnboardingIndex] = useState(0)
  const sendMessageFn = async (e, force = false) => {
      const userMessage = inputValue.trim();
      try {
        gptCoach(userMessage, tasks.filter(t => t.status === 'Pending').map(t => t.stage_name), team.description, team.memo, currentTask, (response) => {
          setChatHistory([...chatHistory, { role: 'user', content: userMessage }, { role: 'assistant', content: response }]);
        }, team?.id, generateCoachingCallsTranscriptions(chatHistory));
      } catch (error) {
        console.error('Error fetching bot response:', error);
      }
  };

   const {
    user,
    chatHistory,
    setChatHistory,
    inputValue,
    sendMessage,
    messageLoading,
    chatContainerRef,
    textareaRef,
    handleInputChange,
    handleKeyDown,
  } = useChatLogic('', sendMessageFn);



  const loadTasks = async () => {
    const od = await getRequest('/coaching/tasks')
    setTasks(od)

    const goals = await getRequest('/coaching/goals')
    setGoals(goals)
  }

  const endCall = () => {
    vcall.stop();
    setVcall(null);
    endCallSound.play();
    vcall.on('message', (m) => {});

    if(callHistory.length === 0){
      setChatHistory([
        {role: 'assistant', content: 'We can always message!' },
      ])
    }else{
      setChatHistory([...callHistory.filter(m => m.role !== 'system'), {role: 'assistant', content: 'We can always message!' }])
    }
  }

  const updateCall = useCallback((message) => {
      if (message.type === 'conversation-update') {
        setCallHistory(message.conversation)
      }
  }, [])

  const initCall = () => {
    const vapi = new Vapi(process.env.REACT_APP_VAPI_PUBLIC_KEY);
    setVcall(vapi);
    const assistantOverrides = {
      transcriber: {
        provider: "deepgram",
        model: "nova-2",
        language: "en-US",
      },
      recordingEnabled: false,
      variableValues: {
        goal: team.description,
        memo: team.memo,
        tasks: tasks.filter((t) => t.status === 'Pending').map((t) => t.stage_name),
        activeTask: currentTask,
      },
    };

    vapi.start("b983a77c-85b0-41c0-9214-6f6659b37b40", assistantOverrides);
    vapi.on('message', (m) => {updateCall(m)});
    startCallSound.play();
  }

  const initChat = () => {
    setChatHistory([
      {role: 'assistant', content: 'No problem, we can just message!' },
      {role: 'assistant', content: 'So, hows it going? Did you get to see your daily recommendations?' },
      {role: 'assistant', content: 'I can also help if you\'ve having problems with anything in your current task.' }
    ]);
  }
  const generateCoachingCallsTranscriptions = (messages) => {
    return messages.map(msg => `${msg.role.charAt(0).toUpperCase() + msg.role.slice(1)}: ${msg.content}`).join('\n\n');
};

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [chatHistory.length]);

 
  useEffect(() => {
    const getUser = async () => {
      const data = await getRequest('/user')
      if(data.error){
        window.location.href = `${process.env.REACT_APP_API_URL}/login`
        return
      }

      window.user = data

      initTracking(data)

      if (data && data.completed < -1) {
        window.location.href = '/enroll/welcome'
      }

      setLoading(false)
    }
    getUser()
    window.reloadUser = () => { getUser() }
  }, [])

  useEffect(() => {
    const getTeam = async () => {
      const data = await getRequest('/team?team_id=' + user.team)
      setTeam(data)
      if(data){
        loadTasks()
      }
    }

    if(user.team){
      getTeam()
    }
  }, [user])


  const addTask = async (name) => {
      await postRequest('/coaching/task/add', JSON.stringify({
        task: name
      }))

    setTaskFormInput('')
    loadTasks()
  }

  const addGoal = async (name) => {
      await postRequest('/coaching/goal/add', JSON.stringify({
        goal: name
      }))

    setTaskFormInput('')
    loadTasks()
  }

  const completeTask = async (name, taskid) => {
    await postRequest('/coaching/task/complete', JSON.stringify({id: taskid}))
    if(vcall !== null){
      vcall.send({
        type: "add-message",
        message: {
          role: "system",
          content: `User marked the task '${name}' marked as complete.`,
        },
      });
      return
    }
    setCurrentTask(null)
    setChatHistory([
      ...chatHistory,
      {role: 'system', content: `Congrats on completeing \n **${name}** 🎉` },

    ])
    window.confetti.add()
    loadTasks()
  }

  const removeTask = async (taskid) => {
    await postRequest('/coaching/task/delete', JSON.stringify({id: taskid}))
    loadTasks()
  }
  const updateGoal = useCallback(async () => {
    setLoading(true)

    await postRequest('/coaching/set_goal', JSON.stringify({
      id: team.id,
      goal: goalInputValue,
      memo: masterpeiceInputValue
    }))
    await loadTasks()
    setLoading(false)
    setOnboardingIndex(0)
    setTeam((team) => {return {...team, description: goalInputValue}})
  }, [goalInputValue, team, masterpeiceInputValue])

  if (loading || !team){
    return <Loader2 />
  }

  if(!team.description){
    return (
      <div className="section-view">
        <Onboarding>

          { onboardingIndex === 0 &&
            <>
              <img src={PoppyImg} alt="Poppy, your ai powered coach" className="big"/>
              <span>Hi {user.name}! Im your coach, Poppy </span>
              <button onClick={() => { setOnboardingIndex(1)}}> Next</button>
            </>
          }

          { onboardingIndex === 1 &&
            <>
              <img src={PoppyImg} alt="Poppy, your ai powered coach" className="big"/>
              <span>We've got some busy weeks coming up. But I'll help you through them</span>
              <button onClick={() => { setOnboardingIndex(2)}}> Next</button>
            </>
          }

          { onboardingIndex === 2 &&
            <>
              <img src={PoppyImg} alt="Poppy, your ai powered coach"/>
              <span>First, write down what your end goal is</span>
              <input type="text" placeholder="I want to create a dog tag business..." value={goalInputValue} onChange={(e) => setGoalInputValue(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter'){setOnboardingIndex(3)}}} />
              {goalInputValue.trim() !== '' && <button onClick={() => {setOnboardingIndex(3)}}> Next </button> }
            </>
          }

          { onboardingIndex === 3 &&
            <>
              <img src={PoppyImg} alt="Poppy, your ai powered coach"/>
              <span>Next, write down your masterpiece memo</span>
              <textarea type="text" placeholder="Your masterpiece memo" value={masterpeiceInputValue} onChange={(e) => setmasterPeiceInputValue(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter'){setOnboardingIndex(4)}}} />
              {masterpeiceInputValue.trim() !== '' && <button onClick={() => {setOnboardingIndex(4)}}> Next </button> }
            </>
          }

          { onboardingIndex === 4 &&
            <>
              <h3>"{goalInputValue}"</h3>
              <h4>Masterpeice memo: {masterpeiceInputValue}</h4>
              <img src={PoppyImg} alt="Poppy, your ai powered coach" className="big"/>
              <span>Great, I'll help you achieve your goal step by step.</span>
              <button onClick={() => {updateGoal()}}> I'm ready to achive this </button>
              <button onClick={() => {setOnboardingIndex(2)}} className="secondary"> I want to edit my answers</button>
            </>
          }
        </Onboarding>
      </div>
    )
  }
  return (
    <Container>
      <Header> {team.description}
          <div onClick={() => {setTeam((team) => {return {...team, description: ''}}); setGoalInputValue(team.description)}}> <Icon name="pencil-in-frame"></Icon> </div>
      </Header>

      { taskGoalFormOpen &&
        <TaskGoalForm>
          <input
            value={taskFormInput}
            onKeyDown={(e) => {
              if(e.key === 'Enter'){
              e.stopPropagation()
                console.log(e.target.value)
              setTaskGoalFormOpen(false)

              if(tabMode === 'tasks'){
                addTask(taskFormInput)
              }else{
                addGoal(taskFormInput)
              }
            }}}
            onChange={(e) => setTaskFormInput(e.target.value)}
            type="text" placeholder={tabMode === 'tasks' ? "Write down your custom task (Max 250 characters)" : "Write down your custom goal (Max 250 characters)"} />
          <span> | </span>
          <button onClick={() => {
            setTaskGoalFormOpen(false)

            if(tabMode === 'tasks'){
              addTask(taskFormInput)
            }else{
              addGoal(taskFormInput)
            }

          }}> Done</button>
        </TaskGoalForm>
      }

      <Todos>
        {tabMode === 'overview' &&
        <Tasks>
            <h3>
              Project Goals
              <div className="actions">
                <div onClick={() => setTaskGoalFormOpen(!taskGoalFormOpen)} >
                  <Icon name="add-task"></Icon>
                </div>

                { tabMode === 'tasks' &&
                  <div onClick={() => setTabMode('overview')}>
                    <Icon name="overview"></Icon>
                  </div>
                }
                { tabMode === 'overview' &&
                  <div onClick={() => setTabMode('tasks')}>
                    <Icon name="tasks"></Icon>
                  </div>
                }

              </div>
            </h3>

            { goals.map((goal,i) =>
              <Goal key={i + goal.stage_name}>
                <Icon name="cart"></Icon>
                <div>
                  <span>{goal.stage_name}</span>
                  <p>x/{extractKeyNumber(goal.stage_name)}</p>
                </div>
                <button> Add update </button>
              </Goal>
            ) }
          </Tasks>
        }

        {tabMode === 'tasks' &&
          <Tasks>
            <h3>
              Current Tasks
              <div className="actions">
                <div onClick={() => setTaskGoalFormOpen(!taskGoalFormOpen)} >
                  <Icon name="add-task"></Icon>
                </div>

                { tabMode === 'tasks' &&
                  <div onClick={() => setTabMode('overview')}>
                    <Icon name="overview"></Icon>
                  </div>
                }
                { tabMode === 'overview' &&
                  <div onClick={() => setTabMode('tasks')}>
                    <Icon name="tasks"></Icon>
                  </div>
                }

              </div>
            </h3>
            {tasks.filter((task) => task.status === 'Pending').length === 0 && <div className="empty">
              <h1>🎉</h1>
              <span>You completed all your tasks!</span>
            </div>}
            {tasks.filter((task) => task.status === 'Pending').map((task) =>
              <Task
                className={currentTask === task.stage_name ? 'active' : ''}

                key={task.id} onClick={() => {
                setCurrentTask(task.stage_name)

                if(vcall !== null){
                    vcall.send({
                      type: "add-message",
                      message: {
                        role: "system",
                        content: `user updated the task they're working on to: ${task.stage_name}`,
                      },
                    });
                    return
                }

                if(chatHistory.length === 0){return}
                setChatHistory([
                  ...chatHistory,
                  {role: 'system', content: task.stage_name},
                ])

                gptCoach(`user updated the task they're working on to: ${task.stage_name}`, tasks.filter(t => t.status === 'Pending').map(t => t.stage_name), team.description, team.memo, currentTask, (response) => {
                  setChatHistory([...chatHistory, { role: 'system', content: task.stage_name }, { role: 'assistant', content: response }]);
                });
              }}
              >
                <div onClick={(e) => {e.stopPropagation();
                  completeTask(task.stage_name, task.id)}}>
                  <Icon name="complete-goal"></Icon>
                </div>
                <span>{task.stage_name} </span>
                <div onClick={() => {removeTask(task.id)}}>
                  <Icon name="remove-goal"></Icon>
                </div>
              </Task>)
            }

            {tasks.filter((task) => task.status === 'Complete').length !== 0 &&
              <h3 style={{marginTop: '72px', backgroundColor: 'transparent'}} > Complete Tasks </h3>
            }
            {tasks.filter((task) => task.status === 'Complete').map((task) =>
              <Task key={task.id} style={{pointerEvents: 'none', opacity: '0.5'}}>
                <Icon name="completed-goal"></Icon>
                <span> {task.stage_name} </span>
              </Task>
            )}
          </Tasks>
        }

        <>

        {vcall === null && chatHistory.length > 0 &&
          <div className="header">Your Coach
            <button onClick={() => {
              setChatHistory([])
              initCall()
            }}
            >
              <Icon name="answer"></Icon>
              Call Poppy
            </button>
          </div>
        }

        <Chat className="coach" ref={chatContainerRef}>
        {chatHistory.length === 0 &&
          <PoppyCalling 
            initCall={initCall}
            endCall={endCall}
            vcall={vcall}
            initChat={initChat}
          />
        }

        {chatHistory.map((message, index) => (
          <Message className={message.role} key={index + message.role}>
            <Avatar className={message.role}>
              {message.role === 'assistant' && <img src={Poppy} alt="Sal" />}
              {message.role === 'user' && <img src={user ? user.picture : Anon} alt="Sal" />}
            </Avatar>
            <Markdown className="markdown">{message.content.replace(/<br>/g, '\n')}</Markdown>
          </Message>))}

        {messageLoading && <Loader> <span className="loader"></span> </Loader>}
        </Chat>

        </>

        {chatHistory.length > 0 &&
        <ChatInputContainer className="coach">
          <ChatInput
            placeholder="Chat with Poppy"
            value={messageLoading ? '' : inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            ref={textareaRef}
          />
          <Button onClick={(e) => { sendMessage(e, true); }}><Icon name="upload"></Icon> </Button>
        </ChatInputContainer>
      }

      </Todos>
    </Container>
  );
};

export default Coaching;
