import React, { useEffect } from 'react';
import Content from './../components/Content';
import { LinkRenderer } from './../utils'

import { Message, Avatar  } from './../views/styles'
import Poppy from './../public/poppy.png';
import Anon from './../public/anon.png';

import Markdown from 'react-markdown'


const MessageComponent = ({ message, messageRef, index, user }) => {

    useEffect(() => {
        if (messageRef?.current) {
            messageRef.current.querySelectorAll('.content, p, h3, ul, ol, pre').forEach((element, idx) => {
                const delay = 0.2 * (idx + 1);
                element.style.setProperty('--index', `${delay}s`);
            });
        }
    }, [message]);

    return (
        <Message className={message.role} ref={messageRef}>

            <Avatar className={message.role}>
                {message.role === 'assistant' && <img src={Poppy} alt="Sal"/>}
                {message.role === 'user' && <img src={user ? user.picture : Anon} alt="Sal"/>}
            </Avatar>

            <Markdown className="markdown" components={{ a: LinkRenderer }}>
                {message.content.replace(/<br>/g, '\n')}
            </Markdown>

            {message.course && <Content content={message.course} />}
        </Message>
    );
};


export default MessageComponent;
