import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest, postRequest, PROGRAMS } from '@/utils.js';
import { Sub, AdminView, AdminHeader, AdminSidebarForm, AdminTable, Button, TableHeadingDiv, TableHeadingH1 } from '@/views/styles.js';
import Nav from '@/components/admin/Nav.js';
import ProgressBar from '@/components/admin/ProgressBar.js';
import TaskGoalForm from '@/components/admin/TaskGoalForm.js';

import Icon from '@/components/Icon';
import UserSelect from '@/components/admin/UserSelect';
import GoalsTable from '@/components/admin/GoalsTable';
import TasksTable from '@/components/admin/TasksTable';
import TranscriptsTable from '@/components/admin/TranscriptsTable';
import styled from 'styled-components';
import TeamPoints from '../../components/admin/team/Points';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const StyledTableHeaderContainer = styled(TableHeadingDiv)`
  justify-content: flex-start;
  column-gap: 15px;
`;
const StyledTableTab = styled(TableHeadingH1)`
  color: ${({ isActiveTab }) => (isActiveTab ? 'black' : 'rgba(0, 0, 0, 0.65)')};
  border-bottom: ${({ isActiveTab }) => (isActiveTab ? '2px solid' : 'none')};
  cursor: pointer;
`;
const Teams = () => {
  const navigate = useNavigate();

  const { teamId } = useParams();
  const [editing, setEditing] = useState(null)
  const [activeSub, setActiveSub] = useState(null)
  const [loading, setLoading] = useState(true)
  const [team, setTeam] = useState(null)
  const [numPages, setNumPages] = useState(0)
  const [cohorts, setCohorts] = useState([])
  const [users, setUsers] = useState({ advisor: [], user: [] })
  const [isTaskOrGoalFormActive, setIsTaskOrGoalFormActive] = useState(null);
  const [tabActive, setTabActive] = useState("lessons");
  const [sidebarModalData, setSidebarModalData] = useState({
    data: undefined,
    onEdit: undefined,
    onAdd: undefined,
    onRemove: undefined,
    name: undefined,
    customContent: undefined,
  });

  const loadTeam = async () => {
    const data = await getRequest('/admin/team?team_id=' + teamId)
    setTeam(data)
  }

  const loadData = async () => {
    const us = await getRequest('/admin/users')
    const cos = await getRequest('/admin/cohorts')
    setCohorts(cos)
    setUsers(us)
  }

  useEffect(() => {
    loadTeam()
    loadData()
  }, [])

  useEffect(() => {
    if (team) { setLoading(false) }
  }, [team])

  const back = (team) => { navigate('/admin/teams') }

  const teamStatus = (team) => {
    const due_submissions = team.all_submissions.filter((sub) => new Date(sub.due[team.cohort]) <= new Date()).length

    const overdue_weight = ((1 + team.submissions.length) / (1 + due_submissions)) * 0.8
    const revenue_weight = (team.revenue / 500) * 0.2
    const ots = (revenue_weight + overdue_weight)
    if (ots >= 0.5 || ots === 0) {
      return ({ el: (<div className="status">On Track</div>), status: true })
    }
    return ({ el: (<div className="status not">Needs Help</div>), status: false })
  }

  const deleteTeam = async (e) => {
    if (window.confirm("Are you sure you want to delete this resource?")) {
      await getRequest('/admin/team/delete?team_id=' + teamId);
      setEditing(null);
      back()
    }
  };

  const editTeam = async (e) => {
    if (window.confirm("Are you sure you want to save these changes?")) {
      await postRequest('/admin/team/edit', JSON.stringify(e));
      loadTeam();
      setEditing(null);
    }
  };

  const filteredUsers = (type) => {
    const added = type === 'user' ? editing.members : editing.advisors;
    return users[type].filter(
      (user) => !added.find((addedUser) => addedUser.id === user.id)
    );
  };

  const removeTeamMember = (index) => {
    setEditing({
      ...editing,
      members: editing.members.filter((_, i) => i !== index),
    });
  };
  const removeAdvisor = (index) => {
    setEditing({
      ...editing,
      advisors: editing.advisors.filter((_, i) => i !== index),
    });
  };

  const submitted = (sub) => {
    const subIndex = team.submissions.find((x) => x.name === sub)
    if (!subIndex) {
      return (<div className="status not">Not Submitted</div>)
    }
    return (<div className="status good">Submitted</div>)
  }

  const subDate = (sub) => {
    const subIndex = team.submissions.find((x) => x.name === sub)
    if (!subIndex) {
      return (<p></p>)
    }
    const d = new Date(subIndex.submitted_date)
    return (<div>{d.toDateString()}</div>)
  }


  const isFile = (file) => {
    return file?.endsWith('.pdf') ||
      file?.endsWith('.mp4') ||
      file?.endsWith('.mov') ||
      file?.endsWith('.avi') ||
      file?.endsWith('.ogg') ||
      file?.endsWith('.mkv') ||
      file?.endsWith('.png') ||
      file?.endsWith('.jpg') ||
      file?.endsWith('.doc') ||
      file?.endsWith('.docx') ||
      file?.endsWith('.ppt') ||
      file?.endsWith('.pptx')
  }

  const renderSidebarModal = useCallback(() => {
    const data = sidebarModalData.data
    return <TaskGoalForm
      onClose={() => setIsTaskOrGoalFormActive(false)}
      name={sidebarModalData.name}
      onSubmit={data ? sidebarModalData.onEdit : sidebarModalData.onAdd}
      onDelete={data ? sidebarModalData.onRemove : undefined}
      data={data}
      customContent={sidebarModalData?.customContent}
    />
  }, [sidebarModalData])

  const clearModalState = () => {
    setSidebarModalData({
      data: undefined,
      onEdit: undefined,
      onAdd: undefined,
      onRemove: undefined,
      name: undefined,
      customContent: undefined,
    })
  }
  const isProgramAlphaX = team?.program === "alpha-x";
  return (
    <AdminView>
      <Nav />

      <main>
        <AdminHeader>
          {!loading && team &&
            <div className="team-head">
              <div className="team-details">
                <img src={team.logo_url} className="logo" />
                <h1> {team.name} </h1>
                <div className="team">
                  {team.members.map((member, i) => (
                    <img key={i} src={member?.picture} />
                  ))}
                </div>
                <div className="advisor">{team.advisors.map((advisor, i) => (<img key={i} src={advisor?.picture} />))}</div>
              </div>
              <div className="team-status">
                {teamStatus(team).el}
                <ProgressBar progress={Array.from(new Set(team.submissions.map((x) => { return x.name }))).length} max={team.all_submissions.length} status={teamStatus(team).status} />
                <ProgressBar progress={team.revenue} max={500} label="$" status={teamStatus(team).status} />
              </div>
            </div>
          }
          <div>
            <Button className="admin secondary" onClick={() => { setActiveSub(null); setEditing({ ...team }) }}>  Edit Team</Button>
          </div>
        </AdminHeader>

        {loading && <div className="loader"> </div>}

        <StyledTableHeaderContainer>
          <StyledTableTab isActiveTab={tabActive === 'lessons'} onClick={() => setTabActive('lessons')}>Lessons</StyledTableTab>
          {isProgramAlphaX && <StyledTableTab isActiveTab={tabActive === 'points'} onClick={() => setTabActive('points')}>Points</StyledTableTab>}
        </StyledTableHeaderContainer>
        {tabActive === 'lessons' &&
          <>
            <AdminTable>
              <div className="head">
                <p className="team">Module</p>
                <p className="date">Deadline</p>
                <p className="stat">Status</p>
                <p className="date">Submitted</p>
                <p className="stat">Deliverable</p>
              </div>

              {!loading && team.all_submissions.map((sub, i) => {
                return (
                  <Sub key={i}>
                    <div className="team"> <span>{i + 1}</span>  {sub.item} </div>
                    <div className="date"> {sub.due[team.cohort]} </div>
                    <div className="stat"> {submitted(sub.item)} </div>
                    <div className="date"> {subDate(sub.item)} </div>
                    <div className="stat"> <Button className="admin secondary" onClick={() => { setActiveSub(team.submissions.find((x) => x.name === sub.item)) }}> View </Button></div>
                  </Sub>
                )
              }
              )}

            </AdminTable>
            <GoalsTable
              teamId={teamId}
              activeAddOrEditForm={isTaskOrGoalFormActive}
              setActiveAddOrEditForm={setIsTaskOrGoalFormActive}
              setSidebarModalData={setSidebarModalData}
              clearModalState={clearModalState}
            />
            <TasksTable
              teamId={teamId}
              activeAddOrEditForm={isTaskOrGoalFormActive}
              setActiveAddOrEditForm={setIsTaskOrGoalFormActive}
              setSidebarModalData={setSidebarModalData}
              clearModalState={clearModalState}
            />
            <TranscriptsTable
              teamId={teamId}
              activeAddOrEditForm={isTaskOrGoalFormActive}
              setActiveAddOrEditForm={setIsTaskOrGoalFormActive}
              setSidebarModalData={setSidebarModalData}
              clearModalState={clearModalState}
            />
          </>
        }
        {isProgramAlphaX &&
          <TeamPoints  teamId={team.id} teamName={team?.name} />
        }
      </main>

      {editing !== null &&
        <AdminSidebarForm>
          <AdminHeader className="sec-header">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div onClick={() => { setEditing(null) }}>
                <Icon name="close" size={8} ></Icon>
              </div>
              <h2>Edit Team</h2>
            </div>
            <div>
              <Button className="admin danger" onClick={() => { deleteTeam(editing.id) }}> Delete </Button>
              <Button className="admin primary" onClick={() => { editTeam(editing) }}> Save  </Button>
            </div>
          </AdminHeader>

          <form>

            <div className="row">
              <img src={editing.logo_url || 'https://via.placeholder.com/150'} alt="img" />
              <div className="group img-group">
                <label htmlFor="img">Team Logo</label>
                <input type="text" name="img" id="img" placeholder="upload image" value={editing.logo_url} onChange={(e) => { setEditing({ ...editing, logo_url: e.target.value }) }} />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="name">Team Name</label>
                <input
                  type="text" name="name" id="name" placeholder="Team Salami"
                  value={editing.name}
                  onChange={(e) => { setEditing({ ...editing, name: e.target.value }) }} />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="program">Program</label>
                <select value={editing.program} onChange={(e) => { setEditing({ ...editing, program: e.target.value }) }}>
                  {PROGRAMS.map(program => <option value={program} key={program}>{program}</option>)}
                </select>
              </div>

              <div className="group">
                <label htmlFor="program">Cohort #</label>

                {(cohorts.map(group => group.session).includes(editing.cohort) || !editing.cohort) &&
                  <select value={editing.cohort} onChange={(e) => { setEditing({ ...editing, cohort: parseInt(e.target.value) }) }}>
                    {!editing._id && <option value="" selected default disabled>select cohort</option>}
                    {cohorts.filter(group => group.program === (editing.program || PROGRAMS[0])).map(group => <option value={group.session} key={group.session}>{group.session}</option>)}
                    <option value="-1">+ Add New Group</option>
                  </select>
                }

                {(!cohorts.map(group => group.session).includes(editing.cohort) && editing.cohort) &&
                  <input type="number" name="group" id="group" placeholder="group"
                    value={`${editing.cohort}`}
                    onChange={(e) => { setEditing({ ...editing, cohort: parseInt(e.target.value) }) }}
                  />
                }
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="site">Website</label>
                <input
                  type="text" name="site" id="site" placeholder="www.salami.com"
                  value={editing.website}
                  onChange={(e) => { setEditing({ ...editing, website: e.target.value }) }} />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="one">One Line statement</label>
                <textarea
                  type="text" name="one" id="one" placeholder="One line statement"
                  value={editing.description}
                  onChange={(e) => { setEditing({ ...editing, description: e.target.value }) }} />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label>Team</label>

                {editing.members.map((member, index) => (
                  <div key={index} className="user">
                    <img src={member?.picture}/>
                    <p>{member.name}</p>
                    <button type="button" onClick={() => removeTeamMember(index)}>x</button>
                  </div>
                ))}

                <UserSelect
                  options={filteredUsers('user')}
                  onSelect={(member) => { setEditing({ ...editing, members: [...editing.members, member], }) }}
                  placeholder="Add student"
                />
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label>Startup Advisors</label>

                {editing.advisors.map((advisor, index) => (
                  <div key={index} className="user">
                    <img src={advisor?.picture}/>
                    <p>{advisor.name}</p>
                    <button type="button" onClick={() => removeAdvisor(index)}>x</button>
                  </div>
                ))}

                {editing.advisors.length === 0 &&
                  <UserSelect
                    options={filteredUsers('advisor')}
                    onSelect={(advisor) => { setEditing({ ...editing, advisors: [...editing.advisors, advisor], }) }}
                    placeholder="Add startup advisor"
                  />
                }
              </div>
            </div>

          </form>
        </AdminSidebarForm>
      }

      {activeSub &&
        <AdminSidebarForm>
          <AdminHeader className="sec-header">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div onClick={() => { setActiveSub(null) }}>
                <Icon name="close" size={8} ></Icon>
              </div>
              <h2>{activeSub.name}</h2>
            </div>
          </AdminHeader>

          <form>
            <div className="row">
              <div className="group">

                {isFile(activeSub.content) && activeSub.content.endsWith('.mp4') &&
                  <video src={`${process.env.REACT_APP_API_URL}/file/${activeSub.content}`} controls="true"></video>
                }

                {isFile(activeSub.content) && activeSub.content.endsWith('.pdf') &&
                  <>
                    <label htmlFor="sub"> <a href={`${process.env.REACT_APP_API_URL}/file/${activeSub.content}`} target="_blank"> Download Submission </a></label>
                    <Document file={`${process.env.REACT_APP_API_URL}/file/${activeSub.content}`}
                      onLoadSuccess={({ numPages }) => { setNumPages(numPages) }}>
                      {Array.from(
                        new Array(numPages),
                        (_el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        ),
                      )}
                    </Document>
                  </>
                }

                {!isFile(activeSub.content) &&
                  <>
                    <label htmlFor="sub"> <a href={`${process.env.REACT_APP_API_URL}/file/${activeSub.content}`} target="_blank"> Submission  Download</a></label>
                    <textarea type="text" name="sub" id="sub" placeholder="No Submission Availible" value={activeSub.content} />
                  </>
                }
              </div>
            </div>

            <div className="row">
              <div className="group">
                <label htmlFor="feeback">AI Feedback</label>
                <textarea type="text" name="feeback" id="feeback" placeholder="No Feedback Availible" value={activeSub.feedback} />
              </div>
            </div>
          </form>
        </AdminSidebarForm>
      }
      <div id="add-edit-form-portal" />
      {isTaskOrGoalFormActive &&
        renderSidebarModal(sidebarModalData.data, sidebarModalData.onEdit, sidebarModalData.onAdd, sidebarModalData.onRemove, sidebarModalData.name)
      }
      <div id="points-modal" />
    </AdminView>
  );
};

export default Teams;
