import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { getRequest, initTracking } from '@/utils.js';
import Lesson from '@/components/Lesson.js';
import Icon from '@/components/Icon.js';
import Chart from '@/components/Chart.js';
import Loader from '@/components/CoverLoader.js';
import AlphaxCompetition from '@/components/competition/AlphaxCompetition';

import prequelBG from '@/public/icons/prequel-bg.svg'
import RocketImg from '@/public/empty.png'
import Anon from '@/public/anon.png'

import styled from 'styled-components';

const Details = styled.div`
  background-color: var(--bg);
  width: 600px;
  height: calc(100vh - 64px);
  overflow-y: scroll;
  box-sizing: content-box;
  padding: 32px;
  margin-right: ${props => props.program === 'alpha-x' ? '0' : '32px'};
  border-left: 1px solid var(--grey);
  .team{
    img{
      width: 170px;
      height: auto;
    }
  }
`

const Lessons = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--bg);
  position: relative;
  background-image: url(${prequelBG});
  background-repeat: no-repeat;
  background-size: fit;
  background-position: 50% 50%;
  .head{
    position: sticky;
    z-index: 3;
    top: 0;
    padding: 32px;
    background-color: var(--bg);

    &.in{
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
  }

  .lessons{
    padding: 32px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 64px;
    h2{
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
`

const Founders = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  >div{
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    width: 55px;
    align-items: center;
    img{
      width: 48px;
      height: 48px;
      object-fit: cover;
      margin-bottom: 16px;
    }
    span{
      width: 100%;
      text-align: center;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const Empty = styled.div`
  width: 100%;
  background-color: var(--dark);
  border-radius: 16px;
  padding: 32px;
  text-align: center;
  color: var(--white);
  margin: 32px 0;
  margin-bottom: 64px;
  span{
    font-size: 11px;
    padding-top: 8px;
    line-height: 16px;
    display: block;
  }
  h2{
    font-weight: 600;
  }
  img{
    display: block;
    margin: 0 auto;
    width: 75px;
  }

`
const Section = styled.div`
  width: 100%;
  margin-bottom: 64px;

  .edit-team{
    cursor: pointer;
    opacity: 0.8;
    &:hover{
      opacity: 1;
    }
  }

  .inline, .edit-team{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 16px;
    img{
      margin-top: 0;
      object-fit: cover;
      width: auto
    }
    span, p{
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  img{
    width: 100%;
    margin-top: 16px;
    border-radius: 50%;
  }

`

const LeaderBoard = styled.div`
> div{
  display: flex;
  flex-direction: row;
  .rank{ width: 75px }
  .team{ width: 100% }
  .revenue{
    width: 100px;
    text-align: right;
 }
}
.head{
  font-wight: 400;
  font-size: 14px;
  opacity: 0.65;
  padding: 16px 0;
}
.row{
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--shade-dark);
  .team{
    display: flex;
    align-items: center;
  }
  img{
    width: 30px;
    height: 30px;
    object-fit:cover;
    margin: 0;
    margin-right: 8px;
  }
}
`
const Button = styled.button`
  padding: 15px;
  border-radius: 32px;
  border: none;
  background-color: var(--hilight);
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 32px;

  &:hover {
    background-color: var(--teal);
  }
`;

const Playbook = () => {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' })
  const [team, setTeam] = useState(null)
  const [loading, setLoading] = useState(true)
  const [teams, setTeams] = useState([])
  const [lessons, setLessons] = useState({ pre: [], during: [] })
  const [completed, setCompleted] = useState([])
  const [userCompleted, setUserCompleted] = useState([])
  const [teamCompleted, setTeamCompleted] = useState([])

  useEffect(() => {
    const getUser = async () => {
      const data = await getRequest('/user')
      setUser(data)
      window.user = data

      initTracking(data)

      setLoading(false)
    }
    getUser()
    window.reloadUser = () => { getUser() }
  }, [])


  useEffect(() => {
    const getCompletions = async () => {
      if (!user || user.id === '') { return }

      const completionData = await getRequest('/completed-lessons')

      setCompleted(completionData.map((c) => { return c.lesson }))
      setUserCompleted(completionData.filter((c) => c.user === user.id).map((c) => { return c.lesson }))
      setTeamCompleted(completionData.filter((c) => c.team === user.team).map((c) => { return c.lesson }))

      if (user && user.program) {
        const l = await getRequest('/lessons?program=' + user.program)
        setLessons({
          pre: l.filter(lesson => lesson.type.includes('pre')),
          during: l.filter(lesson => !lesson.type.includes('pre')),
        })
      } else {
        const l = await getRequest('/lessons')
        setLessons({
          pre: l.filter(lesson => lesson.type.includes('pre')),
          during: l.filter(lesson => !lesson.type.includes('pre')),
        })
      }
    }

    getCompletions()
  }, [user])

  const loadTeamData = useCallback(() => {
    const getTeam = async () => {
      const data = await getRequest('/team?team_id=' + user.team)
      setTeam(data)
    }

    const getTeams = async () => {
      const data = await getRequest('/teams?cohort_id=' + user.cohort)
      setTeams(data)
    }

    if (user.cohort) {
      getTeams()
      if (user.team) {
        getTeam()
      }
    }

  }, [user])

  useEffect(() => { loadTeamData() }, [user, loadTeamData])

  const nextUp = useMemo(() => {
    const all = [...lessons.pre, ...lessons.during].map((l) => { return l.id })
    const x = user.team ? teamCompleted : userCompleted
    const comps = x.map((c) => { return all.indexOf(c) })
    const sorted = comps.sort((a, b) => { return a - b })

    let t = sorted[0] + 1

    while (t < all.length) {
      if (comps.includes(t)) {
        t++
      } else {
        break
      }
    }

    return t
  }, [lessons, userCompleted, teamCompleted, user.team])

  const lessonRefs = useRef([]);

  useEffect(() => {
    if (lessonRefs.current[nextUp]) {
      lessonRefs.current[nextUp].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [nextUp]);

  useEffect(() => {
    const getUser = async () => {
      const data = await getRequest('/user')
      if (data.error) {
        window.location.href = `${process.env.REACT_APP_API_URL}/login`
        return
      }

      setUser(data)
      window.user = data

      initTracking(data)

      if (data && data.completed < -1) {
        window.location.href = '/enroll/welcome'
      }

      setLoading(false)
    }
    getUser()
    window.reloadUser = () => { getUser() }
  }, [])

  if (loading) {
    return <Loader />
  }
  return (
    <div className="section-view">
      {user &&
        <Lessons>
          <div className="head">
            <h1>Playbook</h1>
            <h2>Steps to build your  {user.program === "alpha-x" ? ' masterpiece' : ' startup'}.</h2>
          </div>

          <div className="lessons">
            <h2> {user.program === "alpha-x" ? 'Explore Your Passions' : ' Pre-Camp: Prepare to Build Your Startup'}</h2>

            {lessons.pre.map((lesson, index) => (
              <Lesson
                key={index}
                user={user}
                active={nextUp === index}
                completed={completed.includes(lesson.id)}
                ref={(el) => (lessonRefs.current[index] = el)}
                lesson={lesson}
              />
            ))}
          </div>

          {user.program === 'betacamp' &&
            <div className="lessons">
              <h2>During Camp: Build Your Startup</h2>
              {lessons.during.map((lesson, index) => (
                <Lesson
                  key={index}
                  user={user}
                  active={nextUp === index + lessons.pre.length}
                  completed={team ? teamCompleted.includes(lesson.id) : userCompleted.includes(lesson.id)}
                  ref={(el) => (lessonRefs.current[lessons.pre.length + index] = el)}
                  lesson={lesson}
                />
              ))}
            </div>
          }

          {user.program === 'alpha-x' && lessons.during.length > 0 &&
            <>
              {lessons.during.reduce((pv, cv) => {
                pv[parseInt(cv.type.split('stage-')[1]) - 1].push(cv)
                return pv
              }, [[], [], [], [], []]).map((stageLessons, index) => (
                <div className="lessons" key={index}>
                  <h2> Stage {index + 1}</h2>
                  {stageLessons.map((lesson, lessonIndex) => (
                    <Lesson
                      key={lessonIndex + lesson.type}
                      user={user}
                      active={nextUp === lessonIndex + lessons.pre.length}
                      completed={team ? teamCompleted.includes(lesson.id) : userCompleted.includes(lesson.id)}
                      ref={(el) => (lessonRefs.current[lessons.pre.length + lessonIndex] = el)}
                      lesson={lesson}
                    />
                  ))}
                </div>
              ))}
            </>
          }
        </Lessons>
      }

      <Details program={user.program}>

        {user.program !== 'alpha-x' ?
          <>
            {teams.length === 0 &&
              <Empty>
                <img src={RocketImg} alt="rocket" />
                <h2>Teams aren't formed yet!</h2>
                <span> Form your BETA Camp startup team - submit your team form by the end of Day 2 of Camp!</span>
                <Button onClick={() => { window.open('https://tally.so/r/n0EYyy') }}>Submit your team!</Button>
              </Empty>
            }

            {user && team &&
              <Section>
                <h1> Your Revenue </h1>
                <div className="edit-team" onClick={() => { window.openEditUserModal() }}
                >
                  <img style={{ width: '26px', height: '26px' }} src={user.team_logo} alt="team logo"></img>
                  <p> {user.team_name} </p>
                  <Icon style={{ width: '16px' }} name="edit"></Icon>
                </div>
              </Section>
            }

            {team &&
              <Section>
                <Chart revenue={team.revenue} sales={team.sales} loadTeamData={loadTeamData}></Chart>
              </Section>
            }

            {team &&
              <>
                <Section>
                  <p>Startup Advisor</p>
                  {team.advisors.length > 0 ? (
                    <Founders>
                      {team.advisors.map((advisor, index) => (
                        <div className="inline" style={{ width: 'auto' }} key={index}>
                          <img src={advisor.picture} alt="advisor" />
                          <span>{advisor.name}</span>
                        </div>
                      ))}
                    </Founders>
                  ) : (
                    <Founders>
                      <div className="inline" style={{ width: 'auto' }}>
                        <img src={Anon} alt="advisor" />
                        <span> No advisors assigned yet </span>
                      </div>
                    </Founders>
                  )}
                </Section>

                <Section>
                  <p> Your team </p>
                  <Founders>
                    {team.users.map((user, index) => (
                      <div key={index}>
                        <img src={user.picture} alt="founder" />
                        {user.name &&
                          <span>{user.name.split(' ')[0].split('@')[0]}</span>
                        }
                      </div>
                    ))}
                  </Founders>
                </Section>
              </>
            }

            {teams.length > 0 &&
              <Section>
                <h1> Revenue Leaderboard </h1>
                <LeaderBoard>
                  <div className="head">
                    <div className="rank">Place</div>
                    <div className="team">Team</div>
                    <div className="rev">Revenue</div>
                  </div>

                  {teams.sort((a, b) => b.revenue - a.revenue).map((team, index) => (
                    <div className="row" key={index}>
                      <div className="rank">{index + 1}</div>
                      <div className="team"> <img src={team.logo_url} alt="team logo"></img> {team.name} </div>
                      <div className="revenue">${team.revenue.toFixed(2)}</div>
                    </div>
                  ))}
                </LeaderBoard>
              </Section>
            }

          </>
          :
          <AlphaxCompetition user={user}/>
        }
      </Details>
    </div>
  );
};

export default Playbook;
