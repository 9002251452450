import React, { useState, useEffect } from "react";
import { ChatInputContainer, Button, ChatInput as StyledChatInput } from "@/views/styles";
import Icon from "@/components/Icon";
import styled from 'styled-components';
import { postRequest } from '@/utils'
import AudioRecorder from '@/components/chat/AudioRecorder';

const HiddenFileInput = styled.input`
  display: none;
`;

const RecommendedPromptContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: 4px;
`;

const StyledRecommendedPrompt = styled.div`
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 15px;
    border-radius: 34px;
    border: 1px solid var(--White-50, rgba(255, 255, 255, 0.05));
    background: rgba(0, 0, 0, 0.10);
    color: rgba(248, 248, 248, 0.65);
    font-size: ${props => props.isHalfScreen ? '9px' : '14px'};
    font-weight: 400;
    cursor: pointer;
    &:hover {
      border-radius: 34px;
      border: 1px solid var(--White-50, rgba(255, 255, 255, 0.05));
      background: rgba(0, 0, 0, 0.20);
      color: rgba(248, 248, 248, 0.65);
    font-weight: 400;
    }
`;

const StyledButton = styled(Button)`
   margin-top: -43px;
`;
const ChatInput = ({ lesson, isContentPage, hiddenFileInput, textareaRef, inputValue, handleInputChange, handleKeyDown, sendMessage, messageLoading, sendFile, askModel, setMessageLoading, isHalfScreen }) => {
    const [recordingState, setRecordingState] = useState('start');
    const [recommendedPrompts, setRecommendedPrompts] = useState([]);

    const loadRecommendedPrompts = async () => {
        if (lesson?.context) {
            postRequest('/starter-prompts/gpt', JSON.stringify({
                user_id: window.user.id,
                context: lesson?.context,
            })).then((response) => {
                setRecommendedPrompts(response?.prompts);
            });
        }
    }

    useEffect(() => {
        loadRecommendedPrompts();
    }, []);

    const handleRecommendedPromptClick = (e, recommendedPrompt) => {
        askModel(recommendedPrompt, true)
    }
    return (
        <>
            {sendFile && hiddenFileInput &&
                <HiddenFileInput
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => {
                        e.preventDefault();
                        sendFile(e);
                    }}
                    accept=".pdf,.doc,.docx,.ppt,.pptx,.png,.jpg,.jpeg"
                />
            }

            <ChatInputContainer className={isContentPage ? "half" : "move"}>
                {hiddenFileInput && <StyledButton onClick={() => hiddenFileInput.current.click()}><Icon name="attach"></Icon> </StyledButton>}
                <StyledChatInput
                    placeholder={recordingState === "generating" ? "Loading" : "Chat with Poppy"}
                    value={messageLoading ? '' : inputValue}
                    ref={textareaRef}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}

                />
                <AudioRecorder disabled={messageLoading} recordingState={recordingState} setRecordingState={setRecordingState} context={lesson?.context} askModel={askModel} setMessageLoading={setMessageLoading} />
                <Button disabled={messageLoading} onClick={(e) => { sendMessage(e, true) }}><Icon name="upload"></Icon> </Button>

                <RecommendedPromptContainer>
                    {recommendedPrompts?.map((recommendedPrompt) => {
                        return (
                            <StyledRecommendedPrompt 
                                isHalfScreen={isHalfScreen} 
                                disabled={messageLoading} 
                                key={recommendedPrompt} 
                                role="button" 
                                onClick={(e) => handleRecommendedPromptClick(e, recommendedPrompt)}
                            >
                                {recommendedPrompt}
                            </StyledRecommendedPrompt>

                        )
                    })}
                </RecommendedPromptContainer>
            </ChatInputContainer>
        </>

    )
}

export default ChatInput;