import React from 'react';
import styled from 'styled-components';

const Header = styled.p`
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 20px;
`;
const EmptySubmissionDiv = styled.div`
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    padding: 8px 12px;
    margin-top: 12px;
    margin-bottom: 48px;
`;
const StyledEmptyText = styled.div`
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
`;
const StyledDataContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid #E2E8F0;
    padding: 14px;
    margin-top: 12px;
    flex-direction: column;
    row-gap: 20px;
`;
const StyledDataText = styled.p`
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledButton = styled.button`
    padding: 8px 16px;
    width: 97px;
    border-radius: 6px;
    border: 1px solid var(--slate-200, #E2E8F0);
    background: #FFF;
    color: #0F172A;
    font-size: 14px;
    font-weight: 600;
`;
function PointsSubmissionSection({ header, data, openPointsMenu }) {

    const generateColumnAction = (currentSubmission) => {
        if (header === 'Student submission') {
            return <StyledButton onClick={() => openPointsMenu(currentSubmission)} >View</StyledButton>
        } 
        return `${currentSubmission.points} Points`
    }
    return (
        <>
            <Header>
                {header}
            </Header>
            {data && data.length > 0 ?
                <StyledDataContainer>
                    {data.map((currentData) => {
                        return (
                            <FlexContainer key={`${currentData.id}-points-submission-section`}>
                                <StyledDataText>{currentData.reason}</StyledDataText>
                                <StyledDataText>{generateColumnAction(currentData)}</StyledDataText>
                            </FlexContainer>
                        )
                    })}
                </StyledDataContainer>
                :
                <EmptySubmission />

            }
        </>
    );
}
export default PointsSubmissionSection;

function EmptySubmission() {
    return (
        <EmptySubmissionDiv>
            <StyledEmptyText>None yet</StyledEmptyText>
        </EmptySubmissionDiv>
    )
}