import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PointsSubmissionSection from './PointsSubmissionSection';
import PointsForm from './PointsForm';
import ReactDOM from 'react-dom';
import { getRequest } from '@/utils.js';

const PointContainerDiv = styled.div`
    margin: 32px;
`;
const StyledPoints = styled.div`
    color: var(--Colors-Emerald-600, #059669);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const PointsTotalLabel = styled.div`
    color: var(--Colors-Emerald-600, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 5px;
`;

const PointsContainerDiv = styled.div`
    margin-bottom: 46px;
    display: grid;
    grid-template-columns: 1fr auto;
`
const StyledButton = styled.button`
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid var(--slate-200, #E2E8F0);
    background: #FFF;
    color: #0F172A;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
`;
function TeamPoints({ teamName, teamId }) {
    const [loadingTeamPoints, setLoadTeamPoints] = useState(false);
    const [pointsData, setPointsData] = useState(false);
    const [studentSubmissionList, setStudentSubmissionList] = useState([]);
    const [teamSubmissionList, setTeamSubmissionList] = useState([]);
    const [pointsSidebarOpen, setPointSidebarOpen] = useState(false);
    const [selectedPointsSubmissionData, setSelectedPointsSubmissionData] = useState({
        reason: '',
        points_awarded: ''
    });

    const loadTeamPoints = async () => {
        setLoadTeamPoints(true);
        const points = await getRequest('/admin/points?team_id=' + teamId);
        setStudentSubmissionList(points?.filter((p) => p.is_submitted_by_student))
        setTeamSubmissionList(points?.filter((p) => !p.is_submitted_by_student))
        setPointsData(points);
        setLoadTeamPoints(false);
    }
    useEffect(() => {
        loadTeamPoints();
    }, []);

    const totalPoints = useCallback(() => {
        if (pointsData && pointsData.reduce) {
            return pointsData?.reduce((total, item) => total + item.points, 0) || 0; // Calculate total points
        }
    }, [pointsData]);

    const openPointsMenu = (selectedSubmission) => {
        setPointSidebarOpen(true);
        setSelectedPointsSubmissionData({
            reason: selectedSubmission.reason,
            points_awarded: selectedSubmission.points,
            img_proof: selectedSubmission.img_proof,
            id: selectedSubmission.id,
        })
    }

    const clearSelectedPointSubmissionDataState = () => {
        setSelectedPointsSubmissionData({
            reason: '',
            points_awarded: ''
        })
    }

    const handleOnClick = () => {
        setPointSidebarOpen(true);
        clearSelectedPointSubmissionDataState();
    }

    const handleOnSubmit = async () => {
        await loadTeamPoints();
        setPointSidebarOpen(false);
    }
    const points = totalPoints();
    return (
        <>
            <PointContainerDiv>
                <Points teamName={teamName} teamId={teamId} loadTeamPoints={loadTeamPoints} totalPoints={points} onClick={handleOnClick} />
                <PointsSubmissionSection header="Student submission" data={studentSubmissionList} isLoading={loadingTeamPoints} openPointsMenu={(selectedSubmission) => openPointsMenu(selectedSubmission)} />
                <PointsSubmissionSection header="Team submission" isLoading={loadingTeamPoints} data={teamSubmissionList} />
            </PointContainerDiv>
            {pointsSidebarOpen && ReactDOM.createPortal( // Use React Portal
                <PointsForm
                    onClose={() => setPointSidebarOpen(false)}
                    data={selectedPointsSubmissionData}
                    teamName={teamName}
                    teamId={teamId}
                    onSubmit={handleOnSubmit}
                />,
                document.getElementById('points-modal') // Specify the portal target
            )}
        </>
    );
}
export default TeamPoints;

function Points({ totalPoints, onClick }) {
    return (
        <>
            <PointsContainerDiv>
                <div>
                    <StyledPoints>
                        {totalPoints} points
                    </StyledPoints>
                    <PointsTotalLabel>total</PointsTotalLabel>
                </div>

                <StyledButton onClick={onClick}>Award Points</StyledButton>

            </PointsContainerDiv>
        </>
    )
}
