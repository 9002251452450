import React from 'react';
import styled from 'styled-components';
import Pentagon from './Pentagon';

const Row = styled.div`
  display: grid;
  grid-template-columns: 20% 68% 10%;
  width: 100%;
  align-items: center;
  padding: 16px 0px;
  border-bottom: ${props => props.isLastRow ? 'none' : '1px solid rgba(255, 255, 255, 0.05)'};
  font-size: 14px;
`;

const Points = styled.span`
  text-align: right;
  color: ${props => props.isFirstPlace ? 'white' : 'var(--White-700, rgba(255, 255, 255, 0.70))'};
`;

const StyledSpan = styled.span`
  color: ${props => props.isFirstPlace ? 'white' : 'var(--White-700, rgba(255, 255, 255, 0.70))'};
`

const CompetitorRow = ({ place, name, points, isLastRow }) => {
    return (
        <Row isLastRow={isLastRow}>
            <Pentagon position={place} />
            <StyledSpan isFirstPlace={place===1}>{name}</StyledSpan>
            <Points isFirstPlace={place===1}>{points}</Points>
        </Row>
    );
};

export default CompetitorRow;