import React, { useState } from 'react';
import styled from 'styled-components';
import CompetitorsList from './CompetitorsList';

const StyledTitle = styled.h1`
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
`;

function AlphaxCompetition({ user }) {
    const [showPointsEarningGuide, setShowPointsEarningGuide] = useState(false);
    return (
        <>
            {!showPointsEarningGuide &&
                <StyledTitle className="text-3xl font-bold mb-6">Leaderboard</StyledTitle>
            }
            <CompetitorsList
                showPointsEarningGuide={showPointsEarningGuide}
                setShowPointsEarningGuide={setShowPointsEarningGuide}
                onBackClick={() => {
                    setShowPointsEarningGuide(false)
                }}
                userId={user?.id}
                teamId={user?.team}
            />
        </>
    );
}
export default AlphaxCompetition;