import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '@/components/Icon.js';
import AdminLogo from '@/public/admin_logo_light.svg';
import Anon from '@/public/anon.png';
import { getRequest } from '@/utils.js';

import styled  from 'styled-components'

const Nav = styled.div`
width: 300px;
height: 100vh;
background-color: var(--white);
border-right: 1px solid var(--admin);
display: flex;
flex-direction: column;
justify-content: space-between;

ul{
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  a{
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    margin: 2px 0;
    padding: 12px 16px;
    border-radius: 8px;
    transition-duration: 0.3s;
    &:hover, &.active{
      background-color: rgba(241, 245, 249, 1);
      transition-duration: 0.3s;
    }

    img{
      margin-right: 8px;
    }
  }
}

.logo {
  margin: 32px 16px;
}

.profile {
  margin: 32px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  span, p{
    color: black;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
  }
}
`;

const NavComp = () => {
  const [user, setUser] = useState({ id: '', name: '', picture: '', email: '', verified: '' })

  useEffect(() => {
    const getUser = async () => {
      const data = await getRequest('/user')
      if(data && !data.admin && window.location.href.includes('/admin')) {
        window.location.href = '/'
      }
      window.localStorage.setItem("user_id", data?.id)
      setUser(data)
    }

    getUser()
  }, [])

  return (
    <Nav>
      <div>
        <img className="logo" src={AdminLogo} alt="logo" />

        <ul>
          <NavLink to="/admin/teams" className={({isActive}) => (isActive ? "active" : 'none')}> <Icon name="teams" size={16} /> Teams </NavLink>
          <NavLink to="/admin/lessons" className={({isActive}) => (isActive ? "active" : 'none')}> <Icon name="lessons" size={16} /> Lessons </NavLink>
          <NavLink to="/admin/resources" className={({isActive}) => (isActive ? "active" : 'none')}> <Icon name="resources" size={16} /> Resources </NavLink>
          <NavLink to="/admin/calls" className={({isActive}) => (isActive ? "active" : 'none')}> <Icon name="admin-video" size={16} /> Calls </NavLink>
        </ul>
      </div>


      <div className="profile">
        <img src={user.picture || Anon} alt="profile"/>
        <div>
          <p>{user.name}</p>
          <span>{user.role}</span>
        </div>
      </div>
    </Nav>
  );
};

export default NavComp;
