import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import styled from 'styled-components';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

const pb_pdf = "https://storage.googleapis.com/prequel-public/beta_camp_pb.pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentContainer = styled.div`
  position: fixed;
  background-color: rgba(0,0,0,0.9);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  overflow: scroll;

  .react-pdf__Document{
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    margin: 0 auto;
    color: var(--base-black)
  }
`;

const CloseButton = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  font-size: 36px;
  padding: 40px;
  cursor: pointer;
`
const DocumentViewer = ({file}) => {
  const [numPages, setNumPages] = useState(null);
  const [show, setShow] = useState(null);
  const [pdf, setPdf] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  window.closeDocViewer = (f) => {
    setShow(false)
  }

  window.openDocViewer = (f) => {
    if(f){
      setPdf(f)
    }
    setShow(true)
  }

  const close = () => {
    window.closeDocViewer()
  }

  return (
    <DocumentContainer style={{display: show ? 'block' : 'none'}} onClick={close}>

      {/* <CloseButton onClick={close}>&#10006;</CloseButton> */}

      { pdf  &&
        <Document
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )}
        </Document>
      }
    </DocumentContainer>
  )
};

export default DocumentViewer ;
