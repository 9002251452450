import React, { useEffect, useState, useCallback } from 'react';
import DailyIframe from '@daily-co/daily-js';
import Tray from '@/components/calls/Tray.js';
import CallFrame from '@/components/calls/Frame.js';
import PeopleBox from '@/components/calls/People.js';
import ChatBox from '@/components/calls/Chat.js';

import { BG, Frame, Header, Sidebar, EmojiReaction, } from './video-styles';

import {
  DailyAudio,
  DailyProvider,
} from '@daily-co/daily-react';

import { getRequest } from '@/utils';
import prequelBG from '@/public/pbg.svg';

const Video = () => {
  const STATE_IDLE = 'STATE_IDLE';
  const STATE_CREATING = 'STATE_CREATING';
  const STATE_JOINING = 'STATE_JOINING';
  const STATE_JOINED = 'STATE_JOINED';
  const STATE_LEAVING = 'STATE_LEAVING';
  const STATE_ERROR = 'STATE_ERROR';
  const STATE_HAIRCHECK = 'STATE_HAIRCHECK';

  const [callObject, setCallObject] = useState(null);
  const [call, setCall] = useState({});
  const [appState, setAppState] = useState(STATE_IDLE);
  const [user, setUser] = useState(null);
  const [raisedHands, setRaisedHands] = useState(new Set());
  const [pinnedParticipant, setPinnedParticipant] = useState(null);
  const [emojiReactions, setEmojiReactions] = useState([]);

  useEffect(() => {
    return () => {
      if (callObject) {
        callObject.destroy().then(() => {
          setCallObject(null);
        });
      }
    };
  }, [callObject]);

  const handleToggleHandRaise = useCallback(() => {
    if (!callObject || !user) return;

    const localParticipantId = callObject.participants().local.session_id;
    setRaisedHands(prev => {
      const newSet = new Set(prev);
      if (newSet.has(localParticipantId)) {
        newSet.delete(localParticipantId);
      } else {
        newSet.add(localParticipantId);
      }
      return newSet;
    });

    callObject.sendAppMessage({
      action: 'toggleHandRaise',
      participantId: localParticipantId
    }, '*');
  }, [callObject, user]);

  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setCallObject(null);
            setAppState(STATE_IDLE);
          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    }

    const handleAppMessage = (event) => {
      if (event.data.action === 'toggleHandRaise') {
        setRaisedHands(prev => {
          const newSet = new Set(prev);
          if (newSet.has(event.data.participantId)) {
            newSet.delete(event.data.participantId);
          } else {
            newSet.add(event.data.participantId);
          }
          return newSet;
        });
      } else if (event.data.action === 'pin') {
        setPinnedParticipant(event.data.participantId);
      } else if (event.data.action === 'unpin') {
        setPinnedParticipant(null);
      }
    };

    handleNewMeetingState();

    events.forEach((event) => callObject.on(event, handleNewMeetingState));
    callObject.on('app-message', handleAppMessage);

    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
      callObject.off('app-message', handleAppMessage);
    };
  }, [callObject]);

  const createFrame = async (b, u) => {
    if(!DailyIframe.getCallInstance()){

      const newCallObject = DailyIframe.createCallObject();
      setCallObject(newCallObject);

      await newCallObject.preAuth({
        url: b.url,
        token: b.token,
      });
      await newCallObject.startCamera();
      await newCallObject.join({
        url: b.url,
        userName: u.name,
      });
    }else{
      setTimeout(() => {
        createFrame(b, u);
      }, 200);
    }
  };

  useEffect(() => {
    const initializeCall = async () => {
      const u = await getRequest('/user');
      const response = await getRequest('/calls');
      setUser(u);
      setCall(response[0]);
      createFrame(response[0], u);
    };
    initializeCall();
  }, []);

  const showCall = [STATE_JOINED].includes(appState);

  return (
    <Frame>
      <BG src={prequelBG}></BG>
      <Header>
        {call.title}
        <div>
          {call.owner?.name}
          <img src={call.owner?.picture} alt="Owner" />
        </div>
      </Header>

      {emojiReactions.map(reaction => (
        <EmojiReaction
          key={reaction.id}
          style={{
            left: `${reaction.position.x}%`,
            top: `${reaction.position.y}%`,
          }}
        >
          {reaction.emoji}
        </EmojiReaction>
      ))}

      {showCall && callObject ? (
        <DailyProvider callObject={callObject}>
          <CallFrame
            raisedHands={raisedHands}
            pinnedParticipant={pinnedParticipant}
            user={user}
            call={call}
          />
          <DailyAudio />
          <Tray
            callObject={callObject}
            isHandRaised={raisedHands.has(callObject.participants().local.session_id)}
            onToggleHandRaise={handleToggleHandRaise}
            onEmojiReaction={setEmojiReactions}
            endCall={() => {
              setAppState(STATE_LEAVING);
              callObject.leave();
              window.location.href = '/';
            }}
            user={user}
            call={call}
          />
          <Sidebar>
            <PeopleBox call={call} user={user} callObject={callObject} raisedHands={raisedHands} endCall={() => {
              setAppState(STATE_LEAVING);
              callObject.leave();
            }}
            />

            <ChatBox user={user} call={call} callObject={callObject} />
          </Sidebar>
        </DailyProvider>
      ) : null}
    </Frame>
  );
};
export default Video;

