import React, { useState } from 'react';

import { RoomProvider } from "@/liveblocks.config.js";
import { CollaborativeEditor } from "./Editor";
import { ClientSideSuspense } from "@liveblocks/react";

import { Modal, Button } from '@/views/styles';
import Icon from '@/components/Icon';

const TextSubmissionModal = ({ lesson, user, onClose, onGetFeedback }) => {
  const [textAreaValue, setTextAreaValue] = useState('');
  const isButtonDisabled = textAreaValue.trim().length < 10;

  return (
    <Modal className="submission text">
      <div className="bg-click" onClick={onClose}></div>
      <div className="body">
        <h1>
          {lesson.submission.item}
          <div onClick={onClose}>
            <Icon name="close" />
          </div>
        </h1>
        <RoomProvider id={`${lesson.id}${user.team ? '-team-' + user.team : '-user-' + user.id}`} initialPresence={{}}>
          <ClientSideSuspense fallback="Loading…">
            {() => (
              <CollaborativeEditor
                onChange={(x) => { setTextAreaValue(x) }}
                placeholder={lesson.submission.example}
              />
            )}
          </ClientSideSuspense>
        </RoomProvider>
        <Button onClick={() => onGetFeedback(textAreaValue)} disabled={isButtonDisabled}>
          Get Feedback
        </Button>
      </div>
    </Modal>
  );
};

export default TextSubmissionModal;
