import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useParticipantIds,
  useLocalParticipant,
  useDaily,
  useDailyEvent,
  useParticipantProperty
} from '@daily-co/daily-react';
import { People, Tabs } from '@/views/video-styles';
import Button from '@/components/Button';


function PeopleBox({ endCall, call, user, callObject, raisedHands }) {
  const [topTab, setTopTab] = useState(0);
  const navigate = useNavigate();
  const participantIds = useParticipantIds();
  const [pinnedParticipant, setPinnedParticipant] = useState(null);
  const localParticipant = useLocalParticipant();
  const daily = useDaily();

  const handlePinParticipant = useCallback((participantId) => {
    if (pinnedParticipant === participantId) {
      setPinnedParticipant(null);
      daily.sendAppMessage({ action: 'unpin' }, '*');
    } else {
      setPinnedParticipant(participantId);
      daily.sendAppMessage({ action: 'pin', participantId }, '*');
    }
  }, [daily, pinnedParticipant]);

  const handleMuteParticipant = useCallback((participantId) => {
    daily.updateParticipant(participantId, { setAudio: false });
  }, [daily]);

  const handleKickParticipant = useCallback((participantId) => {
    daily.updateParticipant(participantId, { eject: true });
  }, [daily]);

  const isOwner = user.name === call?.owner?.name;

  useDailyEvent(
    'app-message',
    useCallback(
      (evt) => {
        if (evt.data.action === 'pin') {
          setPinnedParticipant(evt.data.participantId);
        } else if (evt.data.action === 'unpin') {
          setPinnedParticipant(null);
        }
      },
      []
    )
  );

  return (
    <People>
      <Tabs>
        <div
          onClick={() => setTopTab(0)}
          className={`tab${topTab === 0 ? ' active' : ''}`}
        >
          People
        </div>
        <div
          onClick={() => setTopTab(1)}
          className={`tab${topTab === 1 ? ' active' : ''}`}
        >
          Rooms ({call ? call.breakouts_rooms.length : '0'})
        </div>
      </Tabs>
      {topTab === 0 && (
        <ul>
          <LocalParticipant
            participant={localParticipant}
            user={user}
            isHandRaised={raisedHands.has(localParticipant?.session_id)}
          />
          {participantIds.filter(id => id !== localParticipant?.session_id).map((id) => (
            <RemoteParticipant
              key={id}
              participantId={id}
              isOwner={isOwner}
              onPin={handlePinParticipant}
              onMute={handleMuteParticipant}
              onKick={handleKickParticipant}
              isPinned={pinnedParticipant === id}
              isHandRaised={raisedHands.has(id)}
            />
          ))}
        </ul>
      )}
      {topTab === 1 && (
        <BreakoutRooms endCall={endCall} rooms={call?.breakouts_rooms || []} navigate={navigate} />
      )}
    </People>
  );
}

function LocalParticipant({ participant, user, isHandRaised }) {
  const audioState = useParticipantProperty(participant?.session_id, 'audio');

  return (
    <div>
      You ({user.name}) <span>{audioState ? '🎤' : '🔇'} {isHandRaised ? '✋' : ''}</span>
    </div>
  );
}

function RemoteParticipant({ participantId, isOwner, onPin, onMute, onKick, isPinned, isHandRaised }) {
  const userName = useParticipantProperty(participantId, 'user_name');
  const audioState = useParticipantProperty(participantId, 'audio');

  return (
    <div>
      {userName}
      <span>
        {audioState ? '🎤' : '🔇'}
        {isHandRaised ? '✋' : ''}
      </span>
      {isOwner && (
        <>
          <Button
            onClick={() => onPin(participantId)}
            style={{ padding: '5px 10px', fontSize: '12px' }}
          >
            {isPinned ? 'Unpin' : 'Pin'}
          </Button>
          <Button
            onClick={() => onMute(participantId)}
            style={{ padding: '5px 10px', fontSize: '12px' }}
          >
            Mute
          </Button>
          <Button
            onClick={() => onKick(participantId)}
            style={{ padding: '5px 10px', fontSize: '12px' }}
          >
            Kick
          </Button>
        </>
      )}
    </div>
  );
}

function BreakoutRooms({ endCall, rooms, navigate }) {
  const handleJoinRoom = useCallback((roomId) => {
    endCall()
    navigate(`/video/breakout/${roomId}`);
  }, [navigate, endCall]);

  return (
    <ul>
      {rooms.map((room, index) => (
        <div
          onClick={() => handleJoinRoom(room.id)}
          key={room.id}
        >
          Room {index + 1}
          <span>Join</span>
        </div>
      ))}
    </ul>
  );
}

export default PeopleBox;
