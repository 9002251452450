import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { initTracking, getRequest, gpt } from './../utils'
import Icon from '@/components/Icon';
import usePrompt from "@/components/Prompt";
import useLocalStorageState from 'use-local-storage-state';
import useChatLogic from '@/hooks/useChatLogic';
import ChatLayout from '@/components/chat/ChatLayout';

import { Button, Modal } from './styles'
import { log } from 'react-fullstory';

const Prework = () => {
  const { lessonId } = useParams();
  const {
    user,
    setUser,
    lesson,
    setLesson,
    inputValue,
    messageLoading,
    setMessageLoading,
    chatContainerRef,
    textareaRef,
    hiddenFileInput,
    handleInputChange,
    sendMessage,
    handleKeyDown,
    askModel,
    messageRef,
    sendFile,
    collected,
    setCollected,
  } = useChatLogic(lessonId);

  usePrompt(user)

  const [step, setStep] = useLocalStorageState('prework-step-' + lessonId, { defaultValue: 0 })
  const [steps, setSteps] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [allInputsValid, setAllInputsValid] = useState(false);
  const [validationAttempted, setValidationAttempted] = useState(false);

  const [chatHistory, setChatHistory] = useLocalStorageState('prework-chat-history-' + lessonId, { defaultValue: [] })

  const [complete, setComplete] = useState(false);

  const scroll = useCallback(() => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatContainerRef]);
  
  useEffect(() => {
    scroll();
  }, [chatHistory, scroll]);

  useEffect(() => {
    const getLesson = async () => {
      const data = await getRequest('/lesson?id=' + lessonId)
      console.log(data);
      setLesson(data)
      setSteps(data.steps)
      if (chatHistory.length === 0) {
        setChatHistory([{ ...data.steps[0], role: 'assistant' }])
      }
      const ud = await getRequest('/user');
      setUser(ud);

      initTracking(ud)

      window.user = ud
      scroll()
    }
    if (lessonId) { getLesson() }
  }, [lessonId, chatHistory.length, scroll, setChatHistory, setLesson, setUser]);


  const onEnter = (e) => {
    if (e.key === 'Enter') {
      nextStep();
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  }

  const handleSidebarInput = (name, index, value) => {
    const newCollected = { ...collected };
    const newInputErrors = { ...inputErrors };
    if (!newCollected[name]) {
      newCollected[name] = [];
    }
    newCollected[name][index] = value;

    if (validationAttempted) {
      if (value.trim() === '') {
        newInputErrors[name] = newInputErrors[name] || [];
        newInputErrors[name][index] = 'This field is required';
      } else {
        if (newInputErrors[name]) {
          newInputErrors[name][index] = '';
        }
      }
      setInputErrors(newInputErrors);
    }

    setCollected(newCollected);
  };

  useEffect(() => {
    const validateInputs = () => {
      let allValid = true;
      const newInputErrors = {};

      chatHistory.forEach((message) => {
        if (message.collect) {
          for (let i = 0; i < message.collect.count; i++) {
            if (!collected[message.collect.name] || !collected[message.collect.name][i]) {
              allValid = false;
              newInputErrors[message.collect.name] = newInputErrors[message.collect.name] || [];
              newInputErrors[message.collect.name][i] = 'This field is required';
            }
          }
        }
      });
      setInputErrors(newInputErrors);
      setAllInputsValid(allValid);
    };

    validateInputs();
  }, [collected, chatHistory]);

  const interpolateString = useCallback((template) => {
    return template.replace(/\{\{(\w+[-]?\w+)_(\d+)\}\}/g, (match, key, index) => {
      const array = collected[key];
      if (array && Array.isArray(array)) {
        const arrayIndex = parseInt(index) - 1;
        return array[arrayIndex] !== undefined ? array[arrayIndex] : match;
      }
      return match;
    });
  }, [collected])

  const nextStep = useCallback(() => {
    setValidationAttempted(true);
    if (!allInputsValid) {
      return
    } else {
      setValidationAttempted(false);
    }

    const ns = step + 1
    setStep(ns);

    if (steps[ns].content.includes('user:')) {
      gpt(interpolateString(steps[ns].content.replace('user:', '')), lesson?.context, '', (response) => {
        setChatHistory([...chatHistory, { ...steps[ns], role: 'assistant', content: response }])
      })
    } else {
      if (steps[ns].collect) {
        setChatHistory((ch) => { return [...ch, { ...steps[ns], role: 'user' }] })
      } else {
        setChatHistory((ch) => { return [...ch, { ...steps[ns], role: 'assistant' }] })
      }
    }
  }, [step, steps, chatHistory, allInputsValid, lesson?.context, interpolateString, setChatHistory, setStep])


  const completeLesson = async () => {
    setValidationAttempted(true);
    if (!allInputsValid) {
      return
    } else {
      setValidationAttempted(false);
    }

    await getRequest(`/complete-lesson?completed=${lesson.id}`)
    setComplete(true)
    const es = { emojis: lesson.emojis ? lesson.emojis.split(',') : ['🌈', '⚡️', '💥', '✨', '💫', '🌸'] }
    window.confetti.add(es)
  }

  const startNextTask = async () => {
    const data = await getRequest('/lessons?program=' + user.program)
    const i = data.findIndex(l => l.id === lesson.id)
    const next = data[i + 1]
    if (next) {
      window.location.href = `/${next.type.includes('pre') ? 'prework' : 'lesson'}/${next.id}`
    } else {
      window.location.href = '/'
    }
  }

  useEffect(() => {
    if (messageRef?.current) {
      messageRef.current.querySelectorAll('.content, p, h3, ul, ol, pre').forEach((element, idx) => {
        const delay = 1 * (idx + 1);
        element.style.setProperty('--index', `${delay}s`);
      });
    }
  }, [messageRef, chatHistory[chatHistory.length - 1]]);

  return (

    <ChatLayout
      lesson={lesson}
      user={user}
      chatHistory={chatHistory}
      messageLoading={messageLoading}
      chatContainerRef={chatContainerRef}
      messageRef={messageRef}
      inputValue={inputValue}
      textareaRef={textareaRef}
      hiddenFileInput={hiddenFileInput}
      handleInputChange={handleInputChange}
      handleKeyDown={handleKeyDown}
      sendMessage={sendMessage}
      setMessageLoading={setMessageLoading}
      sendFile={sendFile}
      askModel={askModel}
      isHalfScreen={false}
      onBackClick={() => {
        window.location.href = '/';

      }}
      collected={collected}
      setCollected={setCollected}
      handleSidebarInput={handleSidebarInput}
      onEnter={onEnter}
      validationAttempted={validationAttempted}
      inputErrors={inputErrors}
      isPreWork={true}
    >

      {steps[step]?.prompt && (
        <Button className="cta" onClick={nextStep} >
          {steps[step]?.prompt}
        </Button>
      )}

      {step === steps?.length - 1 && (
        <Button className="cta teal" onClick={completeLesson} >
          Complete Lesson
        </Button>
      )}
      {complete && (
        <Modal>
          <div className="bg-click"></div>
          <div className="body">
            <Icon name="complete"></Icon>
            <span>WOWIE, THAT'S PRETTY GOOD </span>
            <h1>{lesson.name} complete!</h1>

            <Button onClick={() => { startNextTask() }}> Start next lesson </Button>
            <Button className="secondary" onClick={() => { window.location.href = '/' }}> Back home </Button>
          </div>
        </Modal>
      )}
    </ChatLayout>

  );
};

export default Prework;
