import React, { useState } from 'react';
import { Modal, FileUpload } from '@/views/styles';
import Icon from '@/components/Icon';
import { formatFileSize } from '@/utils';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
`;

const StyledSubtitle = styled.h2`
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;

const StyledButton = styled.button`
  font-size: 14px;
  font-weight: 600 !important;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const StyledDivContainer = styled.div`
  gap: 0px !important;
`;

const StyledText = styled.p`
  color: #F8F8F8;
  font-size: 16px;
  font-weight: 400;
  margin-top: 28px !important;
`;


const StyledInput = styled.input`
  border-radius: 16px;
  border: 1px solid rgba(248, 248, 248, 0.10);
  background: var(--White-50, rgba(255, 255, 255, 0.05));
  backdrop-filter: blur(25px);
  padding: 16px;
  color: rgba(248, 248, 248, 0.65);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
  width: 100%;
`

const SubmitPointsModal = ({ title, subtitle, onClose, ctaTitle, onSubmit, isUploadingFile }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    points: '',
    reason: '',
  });
  const handleFileChange = async (e) => {
    const selectedFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const maxSize = 50; // in MB
    if (selectedFile.size > maxSize * 1024 * 1024) {
      alert(`File size exceeds ${maxSize}MB. Please choose a smaller file.`);
      return;
    }
    setFile(selectedFile);
  };

  const handleFileSubmit = (e) => {
    if (uploading) {
      return
    }
    setUploading(true);
    onSubmit(file, formData.reason, formData.points);
    setUploading(false);
  }
  const renderFileUpload = () => (
    <FileUpload>
      <input
        type="file"
        id="fileUpload"
        accept="image/*"
        onChange={handleFileChange}
      />
      <Icon name="upload-green" />
      <p><b className="green">Click to upload</b></p>
      <span>(Max. File size: 5GB)</span>
    </FileUpload>
  );

  const renderFileDetails = () => (
    <FileUpload onClick={() => setFile(null)}>
      <Icon name="file" />
      <p className="green">{file.name}</p>
      <span>File size: {(file.size / 1024 / 1024).toFixed(2)}MB</span>
    </FileUpload>
  );

  const renderUploadingProgress = () => (
    <div className="uploading-progress">
      <div>
        <p>{file.name}</p>
        <span>{formatFileSize(file.size)}</span>
      </div>
      <div className="progress">
        <progress value={progress * 100} max="100" />
        <p>{(progress * 100).toFixed(0)}%</p>
      </div>
    </div>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal className="submission upload">
      <div className="bg-click" onClick={onClose}></div>
      <StyledDivContainer className="body">
        <StyledH1>
          {title}
          <div onClick={onClose}>
            <Icon name="close" size={40} />
          </div>
        </StyledH1>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        <div>
          <StyledText>
            # of Points
          </StyledText>
          <StyledInput
            type="text"
            name="points"
            id="points"
            value={formData.points}
            onChange={handleChange}
            placeholder="# of points"
          />
        </div>
        <div>
          <StyledText>
            Reason
          </StyledText>
          <StyledInput
            type="text"
            name="reason"
            id="reason"
            value={formData.reason}
            onChange={handleChange}
            placeholder="Why should you receive these points?"
          />
        </div>
        <StyledText>
          Proof
        </StyledText>
        {file === null ? renderFileUpload() : uploading ? renderUploadingProgress() : renderFileDetails()}

        <StyledButton disabled={isUploadingFile || formData.reason === '' || formData.points === ''} onClick={handleFileSubmit}>
          {file ? (
            <>
              {uploading || isUploadingFile ? (
                <>
                  <div className="inline-loader"></div>
                  {progress === 100 ? ctaTitle : "Uploading..."}
                </>
              ) : ctaTitle}
            </>
          ) : ctaTitle}
        </StyledButton>
      </StyledDivContainer>
    </Modal>
  );
};

export default SubmitPointsModal;