import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CompetitorRow from './CompetitorRow';
import PointsEarningGuide from './PointsEarningGuide';
import { getRequest } from '@/utils.js';
import SubmitPointsModal from './SubmitPointsModal';
import { ToastContainer, toast } from 'react-toastify';

const StyledContainer = styled.div`
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    padding-bottom: 0px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

const StyledTitle = styled.h2`
    font-size: 16px;
    font-weight: 700;
`;

const StyledSubtitle = styled.div`
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    margin-top: 16px;
    display: flex;
    justify-content: end;
`;

const StyledListHeader = styled.div`
  display: grid;
  grid-template-columns: 20% 65% 10%;
  color: #a0aec0;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`;
const StyledButton = styled.button`
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
    border-radius: 8px;
    background: #6A53F5;
`;

const StyledNoList = styled.p`
    text-align: center;
    padding: 20px;
    border-radius: 16px;
    font-size: 12px;
    border: 1px solid var(--White-50, rgba(255, 255, 255, 0.05));
`
const CompetitorsList = ({ showPointsEarningGuide = false, setShowPointsEarningGuide, onBackClick, userId, teamId }) => {
    const [loadingTeamPoints, setLoadTeamPoints] = useState(false);
    const [pointsData, setPointsData] = useState([]);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const loadTeamsPoints = async () => {
        setLoadTeamPoints(true);
        const points = await getRequest('/team/points');
        setPointsData(points);
        setLoadTeamPoints(false);
    }
    useEffect(() => {
        loadTeamsPoints();
    }, []);

    const handleSubmit = async (file, reason, points) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('submitted_by', userId);
        formData.append('team_id', teamId);
        formData.append('reason', reason);
        formData.append('points', points);

        setIsUploadingFile(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/team/points/student/submission`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data.error) {
                toast.error("An unexpected error occurred. Please try again later!", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })
            } else {
                loadTeamsPoints();
                setIsUploadModalOpen(false)
                toast.success("Great work! 🎉 You moved up the leaderboard", {
                    theme: "dark",
                    hideProgressBar: true,
                    closeOnClick: false,
                })

            }

        } catch (error) {
            toast.error("An unexpected error occurred. Please try again later!", {
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
            })
        }
        setIsUploadingFile(false)
    };

    return (
        <>
            {showPointsEarningGuide ? <PointsEarningGuide onBackClick={onBackClick} /> :
                <>
                    <StyledHeader>
                        <StyledTitle>Competitors</StyledTitle>
                        <StyledButton onClick={() => setIsUploadModalOpen(true)}>Submit Points</StyledButton>
                    </StyledHeader>
                    {!loadingTeamPoints && pointsData && pointsData.length > 0 ?
                        <StyledContainer>
                            <StyledListHeader>
                                <span>Place</span>
                                <span>Team</span>
                                <span>Points</span>
                            </StyledListHeader>
                            {pointsData && pointsData?.map((competitor, key) => (
                                <CompetitorRow isLastRow={key + 1 === pointsData.length} key={`competitor-row-${competitor.team_name}`} place={key + 1} name={competitor.team_name} points={competitor.total_points} />
                            ))}
                        </StyledContainer>

                        : <StyledNoList>Competition will begin soon</StyledNoList>
                    }
                    <StyledSubtitle role="button" onClick={() => setShowPointsEarningGuide(true)}>How do I earn points?</StyledSubtitle>
                    {isUploadModalOpen &&
                        <SubmitPointsModal
                            onClose={() => setIsUploadModalOpen(false)}
                            title="Claim Points"
                            subtitle="Let's get you those points!"
                            ctaTitle="Claim Points"
                            onSubmit={handleSubmit}
                            isUploadingFile={isUploadingFile}
                        />
                    }
                    <ToastContainer />
                </>
            }

        </>
    );
};

export default CompetitorsList;