"use client";

import React, { useEffect, useMemo, useState } from "react";
import LiveblocksProvider from "@liveblocks/yjs";
import { Node, createEditor, Editor, Transforms } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { withYjs, YjsEditor } from "@slate-yjs/core";
import * as Y from "yjs";
import { useRoom } from "@/liveblocks.config";
import styles from "./Editor.module.css";

function extractTextFromNodes(nodes) {
  return nodes.map(Node.string).join('\n');
}

export function CollaborativeEditor({onChange, placeholder}) {
  const room = useRoom();
  const [connected, setConnected] = useState(false);
  const [sharedType, setSharedType] = useState();
  const [provider, setProvider] = useState();

  const [plainText, setPlainText] = useState("");

  useEffect(() => {
    onChange(plainText);
  }, [plainText])

  useEffect(() => {
    const yDoc = new Y.Doc();
    const yProvider = new LiveblocksProvider(room, yDoc);
    const sharedDoc = yDoc.get("slate", Y.XmlText);
    yProvider.on("sync", setConnected);

    setSharedType(sharedDoc);
    setProvider(yProvider);

    return () => {
      yDoc?.destroy();
      yProvider?.off("sync", setConnected);
      yProvider?.destroy();
    };
  }, [room]);

  if (!connected || !sharedType || !provider) {
    return <div>Loading…</div>;
  }

  return <SlateEditor sharedType={sharedType} setPlainText={setPlainText} placeholder={placeholder} />;
}

const emptyNode = {
  children: [{ text: "" }],
};

const initialContent = [
  {
    type: 'paragraph',
    children: [{ text: 'Start typing here...' }],
  }
];

function SlateEditor({ sharedType, setPlainText, placeholder }) {
  const [content, setContent] = useState(initialContent);

  const editor = useMemo(() => {
    const e = withReact(withYjs(createEditor(), sharedType));
    const { normalizeNode } = e;
    e.normalizeNode = (entry) => {
      const [node] = entry;
      if (!Editor.isEditor(node) || node.children.length > 0) {
        return normalizeNode(entry);
      }
      Transforms.insertNodes(editor, {
        type: 'paragraph', // Ensure this type matches your schema
        children: [{ text: '' }],
      }, { at: [0] });
    };
    return e;
  }, [sharedType]);

  useEffect(() => {
    YjsEditor.connect(editor);
    return () => YjsEditor.disconnect(editor);
  }, [editor]);

  const onChange = newValue => {
    setContent(newValue);
    setPlainText(extractTextFromNodes(newValue));
  };

  return (
    <div className={styles.container}>
      <div className={styles.editorContainer}>
      <Slate editor={editor} value={content} initialValue={[emptyNode]} onChange={onChange}>
        <Editable className={styles.editor} placeholder={placeholder} />
      </Slate>
      </div>
    </div>
  );
}
